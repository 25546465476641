import React, { useState, useEffect } from 'react'
import { Input, Form, Modal, Radio, Switch, Spin, message } from 'antd'
import benefitstyle from '../style/ClientBenefits.module.css'
import apiRequest from '../api/apiRequest'
import { useSelector } from 'react-redux'
import { purchaseOffset } from '../redux/actions/benefits'
import { useDispatch } from 'react-redux'
import ContributionFeedback from './ContributionFeedback'

export default function AddBenefit({ visible, setVisible, data }) {
  const { title, moneyToCarbonRatio } = data
  const dispatch = useDispatch()
  //states
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const [feedbackModal, setFeedbackModal] = useState(false)
  const [amountDonated, setAmountDonated] = useState(false)

  const triggerAnimation = (time) => {
    setTimeout(
      () =>
        window.confetti({
          particleCount: 1000,
          spread: 200,
          origin: { y: 0.6 },
        }),
      time
    )
  }
  const onSubmit = async () => {
    setLoading(true)

    var tempData = {
      contribution: parseInt(amount * (1 / moneyToCarbonRatio)),
      benefitId: data._id,
    }
    let result = await dispatch(purchaseOffset(tempData))

    if (result) {
      triggerAnimation(100)
      triggerAnimation(1500)
      triggerAnimation(2800)
      triggerAnimation(4300)
      triggerAnimation(6000)

      setAmountDonated(amount)
      setLoading(false)
      setFeedbackModal(true)
      setVisible(false)
    }
  }

  //reset forms functions
  const onReset = () => {
    setAmount(0)
  }
  return (
    <>
      <ContributionFeedback
        selectedBenefit={data}
        visible={feedbackModal}
        setVisible={setFeedbackModal}
        amountDonated={amountDonated}
      />
      <Modal
        title={title}
        visible={visible}
        onOk={() => {}}
        onCancel={() => {
          setVisible(false)
          onReset()
        }}
        width={700}
      >
        <div className='row'>
          <div className={`col-md-12`}>
            <Form.Item>
              <Input
                style={{
                  width: '440px',
                }}
                placeholder={
                  data.type == 'Carbon Removal'
                    ? 'How many tons of carbon do you want to offset?'
                    : data.type == 'Renewable Energy Credits'
                    ? 'How many RECs do you want to buy?'
                    : ''
                }
                min={0.5}
                type='number'
                className={benefitstyle.input}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className='w-100'></div>
          <div className={`col-md-6`}>
            <p style={{ width: 'auto' }}>
              Amount : ${parseInt(amount * (1 / moneyToCarbonRatio))}
            </p>
          </div>
          <div className='col-md-12'>
            {loading ? (
              <div className='ml-3 my-3 mr-0'>
                <Spin />
              </div>
            ) : (
              <button
                type='submit'
                className='primaryBtn'
                onClick={() => onSubmit()}
              >
                Pay Now
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}
