import React, { useState } from "react";
import style from "../style/ClientBenefits.module.css";
import {
  message,
  Form,
  Switch,
  Modal,
  Spin,
  Input,
  Upload,
  Button,
  Popover,
} from "antd";
import { WithContext as ReactTags } from "react-tag-input";
import { setAlertTags } from "../redux/actions/benefits";
import { useDispatch, useSelector } from "react-redux";
import MainCard from "./MainCard";

const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

export default function SustainabilityAlerts() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const tempTags = useSelector((state) => state.benefits.tags?.tags);
  const alerts = useSelector((state) => state.benefits.alerts);
  const user = useSelector((state) => state.auth.user);

  const [tags, setTags] = useState(
    tempTags.map((item) => ({ id: item, text: item }))
  );

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const onSave = async () => {
    if (tags.length > 0) {
      setLoading(true);
      const response = await dispatch(
        setAlertTags({ tags: tags.map((item) => item.text) })
      );
      setLoading(false);
      if (response) {
        Modal.success({
          title:
            "The keywords for your Sustainability Alerts has been updated. Refresh the page to see the updated content.",
        });
      }
    } else {
      Modal.error({ title: "Please add Tags first" });
    }
  };

  return (
    <div>
      {user.type == "employer" ? (
        <>
          <h5 className="mb-4" style={{ maxWidth: "730px" }}>
            Enter keywords below of news topics that you're interested in
            tracking. Every day, Climate Benefits will search millions of
            websites to find articles that match your company's interests.{" "}
          </h5>

          <ReactTags
            tags={tags}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            delimiters={delimiters}
            placeholder={"Enter your keywords here"}
          />

          <div className="col-12  mt-2 pl-0">
            {loading ? (
              <Spin />
            ) : (
              <button
                style={{ width: "100px" }}
                onClick={onSave}
                className="primaryBtn"
              >
                Save
              </button>
            )}
          </div>
        </>
      ) : (
        ""
      )}

      <div className="d-flex mt-5 flex-wrap">
        {alerts.map((item) => (
          <div className="col-xl-6 pl-0" style={{ marginBottom: "30px" }}>
            <MainCard
              Data={{
                ...item,
                shortDescription: item.description,
                outsideImage: true,
                image: item.image_url,
              }}
              imgRoute={"benefits"}
              button={false}
              onButtonClick={(modalType) => {}}
              userType={user.type}
              setDetailVisible={() => {
                window.open(item.link, "_blank");
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
