import React, { useState } from "react";
import {
  Select,
  Input,
  Button,
  Form,
  Modal,
  Radio,
  message,
  Space,
} from "antd";
import benefitstyle from "../../style/ClientBenefits.module.css";
import { getInvolved } from "../../redux/actions/benefits";
import { useDispatch } from "react-redux";
import { fetchDashboardData } from "../../redux/actions/auth";

export default function AddBenefit({
  involvedModal,
  setInvolvedModal,
  benefitId,
}) {
  const [fileChange, setFileChange] = useState("");

  const { Option } = Select;
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    getInvolved({ ...values, benefitId }).then((response) => {
      if (response.status === 200) {
        message.success(
          "Congratulations! Your donation will have a direct carbon impact on the planet."
        );
        dispatch(fetchDashboardData());
      } else {
        message.error(response.data);
      }
    });
  };

  return (
    <Modal
      title="Get Involved"
      visible={involvedModal}
      onOk={() => {}}
      onCancel={() => setInvolvedModal(false)}
    >
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <div className="row">
          <div className="col-md-12 mb-3">
            We'll send a message to the benefits provider with your email
            address. They will follow up via email about the volunteering
            activity.
          </div>
          <div className={`col-md-12`}>
            <Form.Item
              label=""
              name={"email"}
              rules={[
                {
                  required: true,
                  message: `Please enter your email`,
                },
              ]}
            >
              <Input
                placeholder={"Please Enter your Email"}
                className={benefitstyle.input}
              />
            </Form.Item>
          </div>
          <div className="col-md-12">
            <button
              onClick={() => {
                setInvolvedModal(false);
              }}
              type="submit"
              className="primaryBtn"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
