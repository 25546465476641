import React, { useEffect } from "react";
import style from "../style/ClientBenefits.module.css";
import Integration from "../components/Integration";
import { updateUser, getCurrency } from "../redux/actions/auth";
import { connect } from "react-redux";
import { Form, Input } from "antd";
import { useState } from "react";
import { Tooltip, Switch, Select } from "antd";
import { useSelector } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import StripeConnect from "../components/StripeConnect";
import { Tabs } from "antd";
import LeaderBoard from "../components/LeaderBoard";
import { useLocation } from "react-router-dom";
import Developer from "./Developer";
import currencies from "../api/currencies.json";
import { currencies as currency } from "../utils";
import Payments from "../components/Payments";
import APIKeys from "./APIKeys";
import Communications from "./Communications";
const { TabPane } = Tabs;

const mapDispatch = (dispatch) => ({
  updateUser: (data) => dispatch(updateUser(data)),
  getCurrency: (data) => dispatch(getCurrency(data)),
});

const mapState = (state) => ({
  user: state.auth.user,
});
const { Option } = Select;

function Setting({ Data, updateUser, user, getCurrency }) {
  const location = useLocation();
  const pageTitle = user.type;

  const [tabKey, setTabKey] = useState("1");
  const [locationTab, setLocationTab] = useState(true);
  const [cardData, setCardData] = useState({ cvc: "", expiry: "", card: "" });
  let rates = useSelector((state) => state.auth.currencyRates.rates);
  let usdCurrency = useSelector((state) => state.auth.usdCurrency.rates);

  const onFinish = async (values) => {
    if (user.details.currency !== "USD") {
      if (values.employeeMatching)
        values.employeeMatching = Number.parseFloat(
          values.employeeMatching * rates["USD"]
        ).toFixed(2);

      if (values.monthlyFund)
        values.monthlyFund = Number.parseFloat(
          values.monthlyFund * rates["USD"]
        ).toFixed(2);
    }
    updateUser({ ...values, ...cardData });
  };

  const onFinishFailed = (errorInfo) => {};

  useEffect(() => {
    if (user.type == "employer" && user.details) {
      const { cvc, expiry, card } = user.details;
      setCardData({ cvc, expiry, card });
    }
    document
      .querySelector("#google_translate_element")
      .classList.add("visible_translate");
    return () => {
      document
        .querySelector("#google_translate_element")
        .classList.remove("visible_translate");
    };
  }, []);

  let val = user;
  delete val["password"];
  const payment = useSelector((state) => state.auth.user.paymentMethods);
  const transactions = useSelector((state) => state.auth.transactions);
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <div className={style.container}>
      <div style={{ paddingBottom: "60px" }}>
        <Tabs
          activeKey={locationTab ? (location?.Tab ? "5" : tabKey) : tabKey}
          onChange={(key) => {
            setLocationTab(false);
            setTabKey(key);
          }}>
          <TabPane tab='Account Information' key='1'>
            <Form
              name='basic'
              initialValues={{
                remember: true,
                ...val,
                ...val.details,
                monthlyFund: Number.parseFloat(
                  val.details.monthlyFund *
                    usdCurrency[
                      user.details.currency ? user.details.currency : "USD"
                    ]
                ).toFixed(2),
                employeeMatching: Number.parseFloat(
                  val.details.employeeMatching *
                    usdCurrency[
                      user.details.currency ? user.details.currency : "USD"
                    ]
                ).toFixed(2),
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              <div
                className='row'
                style={{
                  width: "100%",
                  maxWidth: "700px",
                  marginBottom: "2em",
                }}>
                <div className={`col-md-6`}>
                  <Form.Item
                    label='First Name'
                    labelCol={{ span: 24 }}
                    name={"firstName"}
                    rules={[
                      {
                        required: true,
                        message: `Please input your First Name!`,
                      },
                    ]}>
                    <Input placeholder={"First Name"} className={style.input} />
                  </Form.Item>
                </div>
                <div className={`col-md-6`}>
                  <Form.Item
                    label='Last Name'
                    labelCol={{ span: 24 }}
                    name={"lastName"}
                    rules={[
                      {
                        required: true,
                        message: `Please input your Last Name!`,
                      },
                    ]}>
                    <Input placeholder={"Last Name"} className={style.input} />
                  </Form.Item>
                </div>
                <div className={`col-md-12`}>
                  <Form.Item
                    label='Email'
                    labelCol={{ span: 24 }}
                    name={"email"}
                    rules={[
                      {
                        required: true,
                        message: `Please input your work email!`,
                      },
                    ]}>
                    <Input placeholder={"Work Email"} className={style.input} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <Form.Item
                    label='Password'
                    labelCol={{ span: 24 }}
                    name='password'>
                    <Input
                      placeholder='Password'
                      className={style.input}
                      type='password'
                    />
                  </Form.Item>{" "}
                </div>
                <div className='col-md-6'>
                  <Form.Item
                    label='Confirm Password'
                    labelCol={{ span: 24 }}
                    name='confirmPassword'
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "The two passwords that you entered do not match!"
                          );
                        },
                      }),
                    ]}>
                    <Input
                      type='password'
                      className={style.input}
                      placeholder='Confirm Password'
                    />
                  </Form.Item>{" "}
                </div>

                {user.type == "employer" ? (
                  <div className='col-md-6'>
                    <Form.Item
                      label='Select Currency'
                      labelCol={{ span: 24 }}
                      name='currency'
                      hasFeedback>
                      <Select
                        className={style.input}
                        defaultValue={user.details.currency}
                        style={{ width: "100%" }}
                        onChange={(val) => getCurrency(val)}
                        suffixIcon={
                          <Tooltip title='You can select any global currency for transactions through Climate Benefits. Once selected, your stipend and corresponding contributions will be in the selected currency. That said, all transactions will appear on your bank statement in USD. We use a secure integration to receive up to date currency exchange information.'>
                            <InfoCircleOutlined
                              style={{ color: "rgba(0,0,0,.45)" }}
                            />
                          </Tooltip>
                        }>
                        {currencies.map((item, index) => (
                          <Option value={item.abbreviation} key={index}>
                            {item.abbreviation} {item.currency}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>{" "}
                  </div>
                ) : (
                  ""
                )}

                {Data.map((item, index) =>
                  item.value == "monthlyFund" ? (
                    <div className={`col-md-6`} key={index}>
                      <Form.Item
                        label='Monthly Stipend for each Employee'
                        labelCol={{ span: 24 }}
                        name={item.value}
                        rules={[
                          {
                            required: true,
                            message: `Please input your ${item.title.toLowerCase()}!`,
                          },
                        ]}>
                        <Input
                          placeholder=''
                          className={style.input}
                          type='number'
                          prefix={
                            <span style={{ color: "rgba(0,0,0,.45)" }}>
                              {currency[user.details.currency]}
                            </span>
                          }
                          suffix={
                            <Tooltip title="This is the amount of funds that each employee can contribute per month to Climate Benefits of their choice. Whenever they contribute, your card on file will be charged up to the limit you set. Employees can also contribute out of pocket whenever they'd like. All transactions happen securely via Stripe.">
                              <InfoCircleOutlined
                                style={{ color: "rgba(0,0,0,.45)" }}
                              />
                            </Tooltip>
                          }
                        />
                      </Form.Item>
                    </div>
                  ) : item.value == "employeeMatching" ? (
                    <div className={`col-md-6`}>
                      <Form.Item
                        label='Employee Matching'
                        labelCol={{ span: 24 }}
                        name={item.value}
                        rules={[
                          {
                            required: true,
                            message: `Please input your ${item.title.toLowerCase()}!`,
                          },
                        ]}>
                        <Input
                          placeholder=''
                          className={style.input}
                          type='number'
                          prefix={
                            <span style={{ color: "rgba(0,0,0,.45)" }}>
                              {currency[user.details.currency]}
                            </span>
                          }
                          suffix={
                            <Tooltip title="This is the amount of funds per month you choose to match Employee's contributions. Employees will be able to track this amount from their Benefits dashboard. All transactions happen securely via Stripe.">
                              <InfoCircleOutlined
                                style={{ color: "rgba(0,0,0,.45)" }}
                              />
                            </Tooltip>
                          }
                        />
                      </Form.Item>
                    </div>
                  ) : (
                    <div className={`col-md-${item.col}`}>
                      <Form.Item
                        label=''
                        name={item.value}
                        rules={[
                          {
                            required: true,
                            message: `Please input your ${item.title.toLowerCase()}!`,
                          },
                        ]}>
                        <Input
                          placeholder={item.title}
                          className={style.input}
                        />
                      </Form.Item>
                    </div>
                  )
                )}
                {user.type == "employer" ? (
                  <div className={`col-md-6`}>
                    <Form.Item
                      label='Monthly Rollover'
                      name={"monthlyRollover"}>
                      <Switch />
                    </Form.Item>
                  </div>
                ) : (
                  ""
                )}
                <div className='col-12'>
                  <button className='primaryBtn'>Save</button>
                </div>
              </div>
            </Form>
          </TabPane>
          {user.origType == "benefitprovider" ? (
            <TabPane tab='Get Paid Automatically' key='m2'>
              <p>
                We use Stripe Connect to securely pay you automatically as
                employees on Climate Benefits contribute to your submitted
                benefits.
              </p>
              <div className='row'>
                <div className='col-5'>
                  <StripeConnect />
                </div>
              </div>
            </TabPane>
          ) : (
            ""
          )}

          {user.origType == "employer" ? (
            <>
              <TabPane tab='Integrations' key='2'>
                <div className='row'>
                  <div className='col-5'>
                    <Integration />
                  </div>
                </div>
              </TabPane>
              <TabPane tab='Developers' key='3'>
                <Developer />
              </TabPane>
              <TabPane tab='Engagement' key='4'>
                <LeaderBoard />
              </TabPane>
              <TabPane tab='Communication' key='5'>
                <Communications />
              </TabPane>
              <TabPane tab='Payments' key='6'>
                <Payments />
              </TabPane>
            </>
          ) : (
            ""
          )}
        </Tabs>
      </div>
    </div>
  );
}

export default connect(mapState, mapDispatch)(Setting);
