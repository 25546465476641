import React, { useState } from "react";
import SignUpDesign from "../components/SignUpDesign";
import SignUpForm from "../components/SignUpForm";
import SignSteps from "../components/SignUpSteps";
import Payroll from "../components/Payroll";
import { connect } from "react-redux";
import { signUp, sendRegisterNotification } from "../redux/actions/auth";
import { useHistory } from "react-router-dom";
import AuthSteps from "../components/AuthSteps";
import { useDispatch } from "react-redux";
import { getCurrency } from "../redux/actions/auth";
const mapDispatch = (dispatch) => ({
  createUser: (data) => dispatch(signUp(data)),
});

function SignUp({ Data, createUser }) {
  const [data, setData] = useState(Data[0].SignUpFormData);
  const [signUpStep, setSignUpStep] = useState(false);
  const [showStepForm, setShowStepForm] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const signUp = async (values) => {
    const result = await createUser({
      ...values,
      type: window.location.pathname.split("/")[2],
    });
    dispatch(getCurrency(false));
    if (result)
      if (window.location.pathname.split("/")[2] == "employer")
        setShowStepForm(true);
      else {
        sendRegisterNotification();
        history.push(`/${window.location.pathname.split("/")[2]}`);
      }
  };

  return (
    <div style={{ height: "100vh", backgroundColor: "#ffffff" }}>
      <div className="row h-100">
        <div className="col-lg-5 d-none h-100 d-md-flex">
          <SignUpDesign signUpStep={signUpStep} data={Data[0].SignUpFormData} />
        </div>
        <div
          className="col-md-7 h-100"
          style={{ paddingBottom: "5em", overflow: "auto" }}
        >
          {showStepForm ? (
            <AuthSteps />
          ) : (
            <SignUpForm
              setSignUpStep={() => setSignUpStep(true)}
              onSignup={signUp}
              data={data}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default connect(null, mapDispatch)(SignUp);
