export default {
  SET_USER: "sets the user",
  SET_USER_TYPE: "SET_USER_TYPE",
  SET_EMPLOYEES: "sets employees",
  SET_DASHBOARD_DATA: "SET_DASHBOARD_DATA",
  SET_TRANSACTIONS: "sets transactions",
  DELETE_EMPLOYEE: "DELETE_EMPLOYEE",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  SET_BENEFIT_FEEDBACK: "SET_BENEFIT_FEEDBACK",
  SET_CURRENCY: "SET_CURRENCY",
  SET_CURRENCY_USD: "SET_CURRENCY_USD",
};
