import Types from "../constants/resources";

var defaultState = {
  resources: null,
  active_resource: null,
};

var authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.SET_RESOURCES: {
      let newState = Object.assign({}, state);
      newState.resources = action.payload;
      return newState;
    }
    case Types.SET_ACTIVE_RESOURCE: {
      let newState = Object.assign({}, state);
      newState.active_resource = action.payload;
      return newState;
    }

    default:
      return state;
  }
};
export default authReducer;
