import React from "react";
import style from "../style/signupForm.module.css";
import { resetPasswordCode } from "../redux/actions/auth";
import { Form, Input, Button, Checkbox, message, Space } from "antd";
import { useHistory } from "react-router-dom";

export default function ForgetForm(props) {
  const history = useHistory();
  const onFinish = async (values) => {
    const result = await resetPasswordCode({
      ...values,
      forgotPasswordCode: props.id,
    });
    if (result) {
      message.success(
        "Your Password has been reset successfully. Please login with your new password."
      );
      history.push("/signin");
    }
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <div className={style.signInForm}>
        <h2>Reset Password</h2>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="row">
            <div className="col-md-12">
              <Form.Item
                label=""
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your passsord!",
                  },
                ]}
              >
                <Input placeholder="Password" type="password" />
              </Form.Item>{" "}
            </div>
            <div className="col-md-12">
              <Form.Item
                label=""
                name="confirmPassword"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input placeholder="Confirm Password" type="password" />
              </Form.Item>{" "}
            </div>
            <div className="col-md-12 text-right">
              <Space>
                <button className="primaryBtn" type="submit">
                  Reset Password
                </button>
              </Space>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}
