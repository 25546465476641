import apiRequest from "../../api/apiRequest";
import { message } from "antd";

export const sendFeedback = async (body) => {
  try {
    await apiRequest({
      url: "/feedback",
      method: "post",
      data: body,
    });
    message.success("Thanks! Your feedback helps us improve our service.");
  } catch (error) {
    message.error(error.data.data);
  }
};
