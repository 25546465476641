import React, { useState } from "react";
import style from "../style/nfts.module.css";
import { NftCard } from "../components/nftscard";
import { Col, Row } from "react-bootstrap";
import { NftDownload } from "../components/nftDownload";
import { nftCardsData } from "../data/nfts";
import NftsTable from "../components/tables/nfts";

export const Nfts = () => {
  const [climate, setClimate] = useState(false);
  //   const [involvedModal, setInvolvedModal] = useState(false);

  //   const { Option } = Select;
  //   const handleChange = (value) => {
  //     console.log(`selected ${value}`);
  //   };
  //   const infoCardDataEmployer = [
  //     {
  //       title: "NFT Wallet",
  //       count: 2,
  //     },
  //   ];
  return (
    <div className={style.claimedNft}>
      <div className={style.heading}>
        <div>
          <h1>Nfts</h1>
          <p>
            Every time you contribute on Climate Benefits, we mint a custom NFT
            for you. Below you can track, download, and share your NFTs.
          </p>
        </div>
        <div>
          {climate ? (
            <button
              onClick={() => setClimate(!climate)}
              className={style.claimedBtn}
            >
              <div>Claimed NFTs</div>
              <span></span>
            </button>
          ) : (
            <button
              onClick={() => setClimate(!climate)}
              className={style.UnclaimedBtn}
            >
              <span></span>
              <div>Unclaimed NFTs</div>
            </button>
          )}
        </div>
      </div>
      <div>
        <NftDownload />
      </div>
      <div>
        <Row>
          {nftCardsData.map((item, i) => (
            <Col sm={6} md={4} lg={3} key={i}>
              <NftCard data={item} />
            </Col>
          ))}
        </Row>
        <div className=" my-5">
          <NftsTable />
        </div>
      </div>
    </div>
  );
};
