import React from "react";
import style from "../style/ClientBenefits.module.css";
import teamsStyle from "../style/teams.module.css";
import { message, Tabs, Spin } from "antd";
import employerData from "../data/employer.json";
import CompanyOverview from "../components/CompanyOverview";
import BenefitFeedbackCard from "../components/BenefitFeedbackCard";
import EmployeeBenefitsList from "../components/EmployeeFeedbackList";
import { DatePicker, Space, Modal } from "antd";
import apiRequest from "../api/apiRequest";
import moment from "moment";
import SustainabilityAlerts from "../components/SustainabilityAlerts";
import ReportSettings from "../components/ReportSettings";

const { TabPane } = Tabs;

export default function Reports() {
  const overviewData = employerData[0].SignUpFormData;
  const [selectedYear, setSelectedYear] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const onChange = (date, dateString) => {
    setSelectedYear(dateString);
  };

  const getTaxPdf = () => {
    if (selectedYear) {
      setLoading(true);
      apiRequest({
        method: "get",
        url: `/tax-forms/advocacy/${selectedYear}`,
      })
        .then((res) => {
          window.open(res.data.data, "_blank");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Modal.error({
            title: "Error",
            content: "Unable to create a Tax Forms pdf right now",
          });
        });
    } else message.error("Please Select a Date");
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  return (
    <div className={style.container}>
      <div>
        <Tabs defaultActiveKey='1'>
          <TabPane tab='Company Overview' key='1'>
            <CompanyOverview SignUpFormData={overviewData} />
          </TabPane>
          <TabPane tab='Benefits Feedback' key='2'>
            <BenefitFeedbackCard />
          </TabPane>
          <TabPane tab='Employee Feedback' key='4'>
            <EmployeeBenefitsList />
          </TabPane>
          <TabPane tab='Tax Forms' key='3'>
            <div className='row '>
              <div className='col-md-8'>
                <p>
                  On January 1st, we'll provide you tax forms on this page to
                  claim deductions for contributions made to 501(c)3's on
                  Climate Benefits. According to the tax code, you can claim up
                  to 10% of your company's income with these type of deductions.
                </p>
              </div>{" "}
              <div className='col-md-12 mt-3'>
                <DatePicker
                  onChange={onChange}
                  size='large'
                  picker='year'
                  disabledDate={disabledDate}
                />
                {loading ? (
                  <div className='ml-3 my-3 mr-0'>
                    <Spin color='white' />
                  </div>
                ) : (
                  <a
                    className={`${teamsStyle.mainBtn} ml-5 mr-md-3 mb-2 mb-md-0`}
                    href='#'
                    onClick={() => getTaxPdf()}>
                    Download
                  </a>
                )}
              </div>
            </div>
          </TabPane>
          <TabPane tab='Sustainability News & Alerts' key='5'>
            <SustainabilityAlerts />
          </TabPane>
          <TabPane tab='External Report' key='6'>
            <ReportSettings />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
