import React from "react";
import style from "../style/ClientBenefits.module.css";
import { useParams } from "react-router";
import { getPublicReport } from "../redux/actions/benefits";
import { ConvertCurrency } from "../utils";
import { Helmet } from "react-helmet";

export default function ExternalReport() {
  let [data, setData] = React.useState([]);
  let [restData, setRestData] = React.useState({});
  let [userName, setUserName] = React.useState([]);

  let { userEmail } = useParams();

  const fetchData = async (id) => {
    const dashboard = await getPublicReport(id);
    setRestData(dashboard);
    setUserName(dashboard.name);
    let temp = [
      {
        title: "Participating Employees",
        count: dashboard.participatingEmployees
          ? dashboard.participatingEmployees
          : 0,
        visible: dashboard.participatingEmployees,
      },
      {
        title: "Carbon Dioxide Removed",
        count: `${
          dashboard.carbonDioxideRemoved ? dashboard.carbonDioxideRemoved : "0"
        } lb`,
        visible: dashboard.carbonDioxideRemoved,
      },
      {
        title: "Volunteering Opportunities",
        count: dashboard.volunteeringOpportunities
          ? dashboard.volunteeringOpportunities
          : 0,
        visible: dashboard.volunteeringOpportunities,
      },

      {
        icon: "",
        title: "Number Of Benefits",
        count: `${dashboard.numberOfBenefits}`,
        visible: dashboard.numberOfBenefits,
      },
      {
        icon: "",
        title: "Total Donated",
        count: `$${dashboard.totalDonated}`,
        visible: dashboard.totalDonated,
      },
      {
        icon: "",
        title: "Donated to Carbon Removal",
        count: `$${dashboard.donatedToCarbonRemoval}`,
        visible: dashboard.donatedToCarbonRemoval,
      },
      {
        icon: "",
        title: "Donated to Climate Advocacy",
        count: `$${dashboard.donatedToClimateAdvocacy}`,
        visible: dashboard.donatedToClimateAdvocacy,
      },
      {
        icon: "co2_icon.svg",
        title: "Carbon Footprint",
        count: `${dashboard.carbonFootprint} Tons`,
        visible: dashboard.carbonFootprint,
      },
    ];
    setData(temp);
  };

  React.useEffect(() => {
    fetchData(userEmail);
  }, []);

  if (data.length == 0)
    return (
      <div
        style={{
          width: "100%",
          height: "123vh",
          backgroundColor: "gainsboro",
          paddingTop: "100px",
          marginTop: "-50px",
        }}
      ></div>
    );

  return (
    <div
      style={{
        width: "100%",
        height: "123vh",
        backgroundColor: restData.backgroundColor
          ? restData.backgroundColor
          : "gainsboro",
        paddingTop: "100px",
        marginTop: "-50px",
      }}
    >
      <Helmet>
        <title>{restData.companyName}'s Climate Report</title>
        <meta name="description" content="All In One Climate Change Software" />
      </Helmet>
      <h1
        style={{
          color: restData.textColor ? restData.textColor : "black",
        }}
        className={`mb-4 mt-5 text-center ${style.mainHeading}`}
      >
        {restData.companyName}'s Climate Report
      </h1>

      {restData.logo ? (
        <img
          src={restData.logo}
          alt=""
          style={{
            position: "fixed",
            top: 50,
            left: 50,
            width: "100px",
          }}
        />
      ) : (
        ""
      )}

      <div
        className="d-flex flex-wrap mb-5 container"
        style={{ marginTop: "4em" }}
      >
        {data.map((card) => {
          return card.visible !== null ? (
            <div
              className={`${style.infoCard} mb-3`}
              style={{
                backgroundColor: restData.blockBackgroundColor
                  ? restData.blockBackgroundColor
                  : "white",
              }}
            >
              <div>
                <p
                  style={{
                    color: restData.textColor ? restData.textColor : "black",
                  }}
                >
                  {card.title}
                </p>
                <h6
                  style={{
                    color: restData.textColor ? restData.textColor : "black",
                  }}
                >
                  {card.count}
                </h6>
              </div>
            </div>
          ) : (
            ""
          );
        })}
      </div>

      <div
        style={{
          position: "fixed",
          right: 0,
          left: 0,
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <p className="text-center pt-3">
          Powered by{" "}
          <a href="https://www.climatebenefits.com/" target="_blank">
            Climate Benefits
          </a>{" "}
        </p>
      </div>
    </div>
  );
}
