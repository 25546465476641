const production = {
  // api: {
  //   URL: "https://admin.climatebenefits.com/api/v1/",
  // },
  // serverurl: "https://admin.climatebenefits.com",
  // finchId: "274c96ec-2de5-4639-ab7b-bd2009463795",
  // // stripePublishableKey:
  // //   "pk_test_51I6flkIsqlJ1wpPgdM20YR5nz228dlPt2ps9QD5pS29J26IJ01Wco3xhnUMqUzpa8pAtwRzHmo1eUNS5tpvjaUJy00VlNJHn3j",
  // stripePublishableKey:
  //   "pk_live_51I6flkIsqlJ1wpPgvPI8NXPGwV5aih18qDtzCsFQq52cdznkeUG2yXjAzcflNJDx3MvIRfGwPcU7jBJxTd0suxUY00GrMsQi2W", // production key CB
};

const development = {
  // api: {
  //   URL: "http://localhost:3020/api/v1/",
  // },
  // serverurl: "http://localhost:3020",
  api: {
    URL: "https://admindev.climatebenefits.com/api/v1/",
  },
  serverurl: "https://admindev.climatebenefits.com",

  finchId: "274c96ec-2de5-4639-ab7b-bd2009463795",
  stripePublishableKey:
    "pk_test_51I6flkIsqlJ1wpPgdM20YR5nz228dlPt2ps9QD5pS29J26IJ01Wco3xhnUMqUzpa8pAtwRzHmo1eUNS5tpvjaUJy00VlNJHn3j",
  // stripePublishableKey:
  //   "pk_live_51I6flkIsqlJ1wpPgvPI8NXPGwV5aih18qDtzCsFQq52cdznkeUG2yXjAzcflNJDx3MvIRfGwPcU7jBJxTd0suxUY00GrMsQi2W", // production key CB
};

const config =
  process.env.REACT_APP_STAGE === "production"
    ? production
    : process.env.REACT_APP_STAGE == "development"
    ? development
    : "";

export default {
  ...development,
};
