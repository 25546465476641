import React, { useState } from "react";
import { Input, Form, Modal, Select, Spin } from "antd";
import style from "../style/subHeader.module.css";
import { sendTicket } from "../redux/actions/dashboard";
import { useDispatch } from "react-redux";

export default function SubmitTicketModal({ modalVisible, setModalVisible }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { Option } = Select;
  const { TextArea } = Input;
  const onFinish = async (values) => {
    if (!loading) {
      setLoading(true);
      const response = await dispatch(sendTicket(values));
      setLoading(false);
      setModalVisible(false);
      if (response) {
        Modal.success({
          title: "Ticket Submitted",
          content:
            "We've received your support ticket. You'll receive an automated email shortly connecting you to our support team.",
        });
      }
    }
  };
  return (
    <Modal
      title='Submit Ticket'
      visible={modalVisible}
      onOk={() => {}}
      onCancel={() => setModalVisible(false)}>
      <Form
        name='basic'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}>
        <Form.Item
          labelCol={{ span: 24 }}
          label='Topic'
          name={"topic"}
          rules={[
            {
              required: true,
              message: `Please select a topic`,
            },
          ]}>
          <Select
            className={style.customInput}
            allowClear
            style={{ padding: "0.24em 0" }}>
            <Option value='Benefits'>Benefits</Option>
            <Option value='Team'>Team</Option>
            <Option value='Reports'>Reports</Option>
            <Option value='Communication'>Communication</Option>
            <Option value='Account'>Account</Option>
            <Option value='billing'>Billing</Option>
            <Option value='other'>Other</Option>
          </Select>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          label='Severity'
          name={"severity"}
          rules={[
            {
              required: true,
              message: `Please select a severity`,
            },
          ]}>
          <Select
            className={style.customInput}
            allowClear
            style={{ padding: "0.24em 0" }}>
            <Option value='low'>Low</Option>
            <Option value='medium'>Medium</Option>
            <Option value='high'>High</Option>
          </Select>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          label='Subject'
          name={"subject"}
          rules={[
            {
              required: true,
              message: `Please enter your subject`,
            },
          ]}>
          <Input className={style.customInput} />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          label='Description'
          name={"description"}
          rules={[
            {
              required: true,
              message: `Please enter your message`,
            },
          ]}>
          <TextArea className={style.customInput} rows={3} />
        </Form.Item>

        {loading ? (
          <Spin style={{ color: "white" }} />
        ) : (
          <button type='submit' className='primaryBtn'>
            Submit
          </button>
        )}
      </Form>
    </Modal>
  );
}
