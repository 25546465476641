import React, { useState } from "react";
import style from "../../style/ClientBenefits.module.css";
import { Select, Input, Button, Form, Modal, Radio } from "antd";
import { useDispatch } from "react-redux";
import { createBenefit } from "../../redux/actions/benefits";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import providerData from "../../data/provider.json";

export default function AddBenefit({ addBenefitModal, setAddBenefitModal }) {
  const [fieldTitle, setFieldTitle] = useState();
  const [fileChange, setFileChange] = useState("");
  const [editorState, setEditorState] = useState();

  const dispatch = useDispatch();
  const { Option } = Select;

  const handleChange = (value) => {
    setFieldTitle(value);
  };

  const onEditorChange = (editorState) => {
    setEditorState(editorState);
  };
  const onFinish = async (values) => {
    let html = stateToHTML(editorState.getCurrentContent());
    const data = { ...values, image: fileChange, longDescription: html };
    await dispatch(createBenefit(data));
    setAddBenefitModal(false);
  };
  return (
    <Modal
      title="Add a custom benefit that is only visible and available to your employees"
      visible={addBenefitModal}
      onOk={() => {}}
      onCancel={() => setAddBenefitModal(false)}
      width={700}
    >
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <div className="row" style={{ width: "100%", maxWidth: "700px" }}>
          {providerData[4].submitData.map((item) => (
            <div className={`col-md-${item.col}`}>
              {item.type == "input" ? (
                <Form.Item
                  label=""
                  name={item.value}
                  rules={[
                    {
                      required: true,
                      message: `Please input your ${item.title.toLowerCase()}!`,
                    },
                  ]}
                >
                  <Input
                    placeholder={
                      item.placeholder ? item.placeholder : item.title
                    }
                    className={style.input}
                  />
                </Form.Item>
              ) : item.type == "textarea" ? (
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName={style.editor}
                  onEditorStateChange={onEditorChange}
                />
              ) : item.type == "select" ? (
                <Form.Item
                  label=""
                  name={item.value}
                  rules={[
                    {
                      required: true,
                      message: `Please input your ${item.title.toLowerCase()}!`,
                    },
                  ]}
                >
                  <Select
                    placeholder={`Select a ${item.title}`}
                    style={{ width: "100%", border: "none" }}
                    // placeholder={item.title}
                    className={`${style.input} borderSelect`}
                    onChange={handleChange}
                  >
                    {item.option.map((opt) => (
                      <Option value={opt}>{opt}</Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : item.type == "file" ? (
                <Form.Item
                  label=""
                  rules={[
                    {
                      required: true,
                      message: `Please select an image`,
                    },
                  ]}
                >
                  <input
                    type="file"
                    className={style.input}
                    onChange={(e) =>
                      e.target.files ? setFileChange(e.target.files[0]) : ""
                    }
                  />
                </Form.Item>
              ) : (
                ""
              )}
            </div>
          ))}
          {fieldTitle == "Carbon Removal" ||
          fieldTitle == "Renewable Energy Credits" ? (
            <div className="col-md-12">
              <Form.Item
                label=""
                name={"moneyToCarbonRatio"}
                rules={[
                  {
                    required: true,
                    message:
                      fieldTitle == "Renewable Energy Credits"
                        ? `Please input Tons of RCE does each contributed dollar reduce?!`
                        : `Please input Tons of carbon does each contributed dollar reduce?!`,
                  },
                ]}
              >
                <Input
                  placeholder={
                    fieldTitle == "Renewable Energy Credits"
                      ? "What is your price per ton of REC?"
                      : "What is your price per ton of carbon removed?"
                  }
                  className={style.input}
                />
              </Form.Item>
            </div>
          ) : (
            ""
          )}
          <div className="col-md-12">
            <button type="submit" className="primaryBtn">
              Submit
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
