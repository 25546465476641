import Types from "../constants/resources";
import axios from "axios";
import apiRequest from "../../api/apiRequest";
import { message } from "antd";

// actions
export const setResources = (data) => ({
  type: Types.SET_RESOURCES,
  payload: data,
});

// actions
export const fetchResources = () => async (dispatch) => {
  return await apiRequest({
    method: "get",
    url: "/resources",
  })
    .then((res) => {
      dispatch(setResources(res.data.data));
    })
    .catch((err) => {
      message.error(err.data.data);
      return false;
    });
};

export const setActiveResource = (data) => async (dispatch) => {
  dispatch({ type: Types.SET_ACTIVE_RESOURCE, payload: data });
};
