import TYPES from "../constants/apikeys";
import apiRequest from "../../api/apiRequest";
import { message, Modal } from "antd";

//get api key
export const getAPIKeys = () => async (dispatch) => {
  return await apiRequest({
    method: "get",
    url: "https://admindev.climatebenefits.com/api/v2/getApiKeys",
  })
    .then((res) => {
      dispatch({ type: TYPES.GET_API_KEY, payload: res.data.data });
    })
    .catch((err) => {
      message.error(err.data.data);
      return false;
    });
};

//delete api key
export const deleteAPIKey = (key) => async (dispatch) => {
  const data = {
    apiKey: key,
  };
  return await apiRequest({
    method: "post",
    url: "https://admindev.climatebenefits.com/api/v2/deleteAPIKey",
    data,
  })
    .then((res) => {
      dispatch({ type: TYPES.DELETE_API_KEY });
      dispatch(getAPIKeys());
    })
    .catch((err) => {
      message.error(err.data.data);
      return false;
    });
};

//create api key
export const createAPIKeys = () => async (dispatch) => {
  return await apiRequest({
    method: "get",
    url: "https://admindev.climatebenefits.com/api/v2/CreateAPIKey",
  })
    .then((res) => {
      dispatch({ type: TYPES.CREATE_API_KEY });
      dispatch(getAPIKeys());
    })
    .catch((err) => {
      message.error(err.data.data);
      return false;
    });
};

//get api key
export const getAPILogs = () => async (dispatch) => {
  return await apiRequest({
    method: "get",
    url: "https://admindev.climatebenefits.com/api/v2/getApiLogs",
  })
    .then((res) => {
      dispatch({ type: TYPES.API_KEY_LOGS, payload: res.data.data });
    })
    .catch((err) => {
      message.error(err.data.data);
      return false;
    });
};
