import React, { useState } from "react";
import { Select, Input, Button, Form, Modal, Spin } from "antd";
import benefitstyle from "../style/ClientBenefits.module.css";
import { useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { editBenefit } from "../redux/actions/benefits";
import { stateToHTML } from "draft-js-export-html";
import style from "../style/ClientBenefits.module.css";
import BenefitData from "../data/provider.json";
const { Option } = Select;

export default function EditBenefit({ setModalVisible, isModalVisible }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [fieldTitle, setFieldTitle] = useState();
  const [fileChange, setFileChange] = useState("");
  const [editorState, setEditorState] = useState();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isModalVisible) {
      const contentDataState = ContentState.createFromBlockArray(
        convertFromHTML(`${isModalVisible.longDescription}`)
      );
      const editorDataState = EditorState.createWithContent(contentDataState);
      setEditorState(editorDataState);
      setFieldTitle(isModalVisible.type);
      form.setFieldsValue({
        title: isModalVisible.title,
        shortDescription: isModalVisible.shortDescription,
        industry: isModalVisible.industry,
        url: isModalVisible.url,
        benefitType: isModalVisible.type,
        moneyToCarbonRatio: isModalVisible.moneyToCarbonRatio,
      });
    } else
      form.setFieldsValue({
        firstName: "",
        lastName: "",
        email: "",
      });
  }, [isModalVisible]);

  const handleChange = (value) => {
    setFieldTitle(value);
  };

  const onEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  const onFinish = async (values) => {
    setLoading(true);
    await dispatch(editBenefit({ ...values, _id: isModalVisible._id }));
    setLoading(false);
    setModalVisible(false);
  };

  const onFinishFailed = (errorInfo) => {};

  const Data = BenefitData[4].submitData;

  return (
    <Modal
      footer={null}
      title='Edit Benefit'
      visible={isModalVisible}
      onOk={() => {
        setModalVisible(false);
      }}
      onCancel={() => setModalVisible(false)}
      width={700}>
      <Form
        name='basic'
        initialValues={{
          remember: true,
        }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <div className='row' style={{ width: "100%", maxWidth: "700px" }}>
          {Data.map((item) => (
            <div className={`col-md-${item.col}`}>
              {item.type == "input" ? (
                <Form.Item
                  label=''
                  name={item.value}
                  rules={[
                    {
                      required: true,
                      message: `Please input your ${item.title.toLowerCase()}!`,
                    },
                  ]}>
                  <Input placeholder={item.title} className={style.input} />
                </Form.Item>
              ) : item.type == "textarea" ? (
                <Form.Item
                  label=''
                  rules={[
                    {
                      required: true,
                      message: `Please input your ${item.title.toLowerCase()}!`,
                    },
                  ]}>
                  <Editor
                    editorState={editorState}
                    toolbarClassName='toolbarClassName'
                    wrapperClassName='wrapperClassName'
                    editorClassName={style.editor}
                    onEditorStateChange={onEditorChange}
                  />
                </Form.Item>
              ) : item.type == "select" ? (
                <Form.Item
                  label=''
                  name={item.value}
                  rules={[
                    {
                      required: true,
                      message: `Please input your ${item.title.toLowerCase()}!`,
                    },
                  ]}>
                  <Select
                    placeholder={`Select a ${item.title}`}
                    style={{ width: "100%", border: "none" }}
                    // placeholder={item.title}
                    className={`${style.input} borderSelect`}
                    onChange={handleChange}>
                    {item.option.map((opt) => (
                      <Option value={opt}>{opt}</Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : item.type == "file" ? (
                <Form.Item
                  label=''
                  name={item.value}
                  rules={[
                    {
                      required: true,
                      message: `Please select an image`,
                    },
                  ]}>
                  <input
                    type='file'
                    className={style.input}
                    onChange={(e) =>
                      e.target.files ? setFileChange(e.target.files[0]) : ""
                    }
                  />
                </Form.Item>
              ) : (
                ""
              )}
            </div>
          ))}
          {fieldTitle == "Carbon Removal" ? (
            <div className='col-md-12'>
              <Form.Item
                label=''
                name={"moneyToCarbonRatio"}
                rules={[
                  {
                    required: true,
                    message: `Please input Tons of carbon does each contributed dollar reduce?!`,
                  },
                ]}>
                <Input
                  placeholder='What is your price per ton of carbon removed?'
                  className={style.input}
                />
              </Form.Item>
            </div>
          ) : (
            ""
          )}
          <div className='col-md-12'>
            {loading ? (
              <div className='ml-3 my-3 mr-0'>
                <Spin />
              </div>
            ) : (
              <button
                type='submit'
                className='primaryBtn'
                style={{ width: "180px" }}>
                Submit
              </button>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
