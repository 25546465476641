import Types from "../constants/auth";

var defaultState = {
  user: null,
  employees: [],
  dashboard: {},
  transactions: [],
  notifications: [],
  benefitFeedback: [],
  currencyRates: {},
  usdCurrency: {},
};

var authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.SET_CURRENCY_USD: {
      let newState = Object.assign({}, state);
      newState.usdCurrency = action.payload;
      return newState;
    }

    case Types.SET_CURRENCY: {
      let newState = Object.assign({}, state);
      newState.currencyRates = action.payload;
      return newState;
    }

    case Types.SET_BENEFIT_FEEDBACK: {
      let newState = Object.assign({}, state);
      newState.benefitFeedback = action.payload;
      return newState;
    }
    case Types.SET_NOTIFICATIONS: {
      let newState = Object.assign({}, state);
      newState.notifications = action.payload;
      return newState;
    }
    case Types.SET_TRANSACTIONS: {
      let newState = Object.assign({}, state);
      newState.transactions = action.payload;
      return newState;
    }

    case Types.SET_DASHBOARD_DATA: {
      let newState = Object.assign({}, state);
      newState.dashboard = action.payload;
      return newState;
    }

    case Types.SET_USER: {
      let newState = Object.assign({}, state);
      newState.user = { ...action.payload, origType: action.payload.type };
      if (action.payload.token)
        localStorage.setItem("auth_token", action.payload.token);
      if (!newState.user.details)
        newState.user["details"] = {
          currency: "USD",
        };
      return newState;
    }

    case Types.SET_USER_TYPE: {
      let newState = Object.assign({}, state);
      newState.user = {
        ...newState.user,
        type: action.payload,
      };

      return newState;
    }
    case Types.SET_EMPLOYEES: {
      let newState = Object.assign({}, state);
      newState.employees = action.payload;

      return newState;
    }

    default:
      return state;
  }
};
export default authReducer;
