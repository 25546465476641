import React, { useState, useEffect } from "react";
import style from "../style/ClientBenefits.module.css";
import { Space, Table, Button, Modal, Form, Spin, Input } from "antd";
import benefitstyle from "../style/ClientBenefits.module.css";
import { useDispatch, useSelector } from "react-redux";
import { QuestionCircleOutlined } from "@ant-design/icons";

import {
  getAPIKeys,
  deleteAPIKey,
  createAPIKeys,
} from "../redux/actions/apikeys";

const APIKeys = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(getAPIKeys());
  }, []);

  const columns = [
    {
      title: "API Number",
      dataIndex: "api_number",
      key: "api_number",
    },
    {
      title: "API Token",
      dataIndex: "api_token",
      key: "api_token",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size='middle'>
          {loading ? (
            <Spin />
          ) : (
            <Button
              type='primary'
              danger
              onClick={() => {
                Modal.confirm({
                  title: "Are you sure you want to delete this key?",
                  icon: <QuestionCircleOutlined />,
                  okText: "Yes",
                  okType: "danger",
                  cancelText: "No",

                  async onOk() {
                    await dispatch(deleteAPIKey(record.api_token));
                  },

                  onCancel() {},
                });
              }}>
              Delete
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const apiKeys = useSelector((state) => state?.apikey?.api_keys);

  const data = [];
  apiKeys?.map((api, index) => {
    data.push({
      key: index,
      api_number: index + 1,
      api_token: api,
    });
  });
  return (
    <>
      <div
        className={`d-lg-flex align-items-start justify-content-between flex-wrap mb-3 ${style.ClientBenefits}`}>
        <div></div>
        <div>
          <button
            onClick={async () => {
              setVisible(true);
              await dispatch(createAPIKeys());
              setVisible(false);
            }}
            className='primaryBtn mt-0 w-100 mr-md-2 mb-3 mb-md-0'
            style={{ minWidth: "200px" }}>
            Create Secret Key
          </button>
        </div>
      </div>
      <Modal
        visible={visible}
        // onOk={() => {
        //   setVisible(false);
        // }}
        // onCancel={() => {
        //   setVisible(false);
        // }}
        width={700}
        className='p-5'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='d-flex align-items-center justify-content-center'>
              <h3 className='mr-2'>Creating Your API Token...</h3>
              <Spin />
            </div>
          </div>
          <div className='w-100'></div>
        </div>
      </Modal>

      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: true }}
      />
    </>
  );
};

export default APIKeys;
