import React from "react";
import { Table, Collapse } from "antd";
import style from "./index.module.css";
// import FilterDropdown from "./dropdown";
import { BsFillTagFill, BsBoxArrowUpRight } from "react-icons/bs";
import {
  RiShoppingCart2Fill,
  RiArrowLeftRightLine,
  RiArrowDownSLine,
  RiArrowUpSLine,
} from "react-icons/ri";

const NftsTable = () => {
  const columns = [
    {
      title: "Activity",
      dataIndex: "activity",
      key: "activity",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Price",
      dataIndex: "price",
      //   render: (__) => (
      //     <a>
      //       <img src="/images/dot.png" alt="" />
      //     </a>
      //   ),
      key: "price",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "To",
      key: "to",
      dataIndex: "to",
      //   render: (_, { tags }) => (
      //     <>
      //       {tags.map((tag) => {
      //         let color = tag.length > 5 ? "geekblue" : "green";

      //         if (tag === "loser") {
      //           color = "volcano";
      //         }

      //         return (
      //           <Tag color={color} key={tag}>
      //             {tag.toUpperCase()}
      //           </Tag>
      //         );
      //       })}
      //     </>
      //   ),
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",

      //   render: (_, record) => (
      //     <Space size="middle">
      //       <a>Invite {record.name}</a>
      //       <a>Delete</a>
      //     </Space>
      //   ),
    },
  ];
  const data = [
    {
      key: "1",
      activity: (
        <div className={style.activitys}>
          <BsFillTagFill />
          <span>Lest</span>
        </div>
      ),
      price: 0.15,
      from: <a href="">Jaff</a>,
      to: "",
      date: "13 hours ago",
    },
    {
      key: "2",
      activity: (
        <div className={style.activitys}>
          <BsFillTagFill />
          <span>Lest</span>
        </div>
      ),
      price: 0.184,
      from: <a href="">Jaff</a>,
      to: "",
      date: "4 hours ago",
    },
    {
      key: "3",
      activity: (
        <div className={style.activitys}>
          <RiShoppingCart2Fill />
          <span>Sale</span>
        </div>
      ),
      price: 0.19,
      from: <a href="">Jaff</a>,
      to: <a href="">Jaff</a>,
      date: (
        <a href="">
          10 days ago <BsBoxArrowUpRight />
        </a>
      ),
    },
    {
      key: "4",
      activity: (
        <div className={style.activitys}>
          <RiArrowLeftRightLine />
          <span>Transfer</span>
        </div>
      ),
      from: <a href="">Richard</a>,
      to: <a href="">Jaff</a>,
      date: (
        <a href="">
          10 days ago <BsBoxArrowUpRight />
        </a>
      ),
    },
    {
      key: "5",
      activity: (
        <div className={style.activitys}>
          <BsFillTagFill />
          <span>Offer</span>
        </div>
      ),
      price: 0.03,
      from: <a href="">lambroke</a>,
      to: "",
      date: "10 days ago",
    },
  ];

  const { Panel } = Collapse;
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <Collapse
      className={style.table}
      defaultActiveKey={["1"]}
      onChange={onChange}
    >
      <Panel
        header={
          <div className={style.collapseBtn}>
            <div>
              <img src="/images/arrows.svg" alt="" />
              <strong>Item Activiy</strong>
            </div>
            <span>
              <RiArrowUpSLine />
            </span>
          </div>
        }
        key="1"
      >
        <div className={style.tableContent}>
          <div className={style.filterBtn}>
            <p>Filter</p>
            <RiArrowDownSLine />
          </div>
          <Table columns={columns} dataSource={data} />
        </div>
      </Panel>
    </Collapse>
  );
};

export default NftsTable;
