import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import style from "../style/ClientBenefits.module.css";
import { useDispatch, useSelector } from "react-redux";
import { addGlobalText } from "../redux/actions/auth";
import {
  message,
  Form,
  Switch,
  Modal,
  Spin,
  Input,
  Upload,
  Button,
  Popover,
} from "antd";
import { Tabs } from "antd";
import EmailEditor from "../components/EmailEditor";
import { generateReport, fetchReport } from "../redux/actions/benefits";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import { SketchPicker } from "react-color";
import { HexColorPicker } from "react-colorful";

export default function ReportSettings() {
  const dispatch = useDispatch();
  const externalReport = useSelector((state) => state.benefits.externalReport);
  const user = useSelector((state) => state.auth.user);

  let initialVal = externalReport?.settings;
  const fileRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [blockBackgroundColor, setBlockBackgroundColor] = useState("");
  const [logo, setLogo] = useState("");
  const [logoFile, setLogoFile] = useState("");

  React.useEffect(() => {
    if (initialVal) {
      setBackgroundColor(initialVal.backgroundColor);
      setTextColor(initialVal.textColor);
      setBlockBackgroundColor(initialVal.blockBackgroundColor);
      setLogoFile(initialVal.logo);
    }
  }, []);

  const onFormFinish = async (values) => {
    setLoading(true);
    let data = {
      ...values,
      blockBackgroundColor,
      textColor,
      backgroundColor,
      logo,
    };

    var form_data = new FormData();

    for (var key in data) {
      form_data.append(key, data[key]);
    }
    const response = await generateReport(form_data);
    setLoading(false);
    if (response) {
      dispatch(fetchReport());
      Modal.success({
        title: "External Report Saved ",
        content: "Your web page has been updated with the data you selected.",
      });
    } else {
      Modal.error({
        title: "External Report Error ",
        content: "Unable to save your report at the moment",
      });
    }
  };

  const onFinishFailed = (errorInfo) => {};
  const layout = {
    labelCol: { span: 19 },
    wrapperCol: { span: 20 },
  };
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const content = (
    <div>
      <HexColorPicker
        color={backgroundColor}
        onChange={(color) => {
          setBackgroundColor(color);
        }}
      />
    </div>
  );

  const contentText = (
    <div>
      <HexColorPicker
        color={textColor}
        onChange={(color) => {
          setTextColor(color);
        }}
      />
    </div>
  );

  const contentBlockBack = (
    <div>
      <HexColorPicker
        color={blockBackgroundColor}
        onChange={(color) => {
          setBlockBackgroundColor(color);
        }}
      />
    </div>
  );
  return (
    <div style={{ paddingBottom: "80px" }}>
      <h1 className={`mb-4 ${style.mainHeading}`}>Share Your Climate Report</h1>
      <h5 className='mb-4'>
        You can share the web page below with your stakeholders. We dynamically
        pull this data from your Climate Benefits dashboard and you can select
        what appears.
      </h5>
      <h6 className='mb-4'>
        Your Report is available at{" "}
        <a
          href={`${window.location.origin}/external-report/${externalReport?.employer}`}
          target='_blank'>
          {window.location.origin}/external-report/{user._id}
        </a>
      </h6>
      <Form
        {...layout}
        name='basic'
        initialValues={{
          ...initialVal,
        }}
        form={form}
        onFinish={onFormFinish}
        onFinishFailed={onFinishFailed}>
        <div className='row mx-4 reportsSettings'>
          <div className='col-md-4'>
            <Form.Item
              label='Participating Employees'
              name={"participatingEmployees"}
              valuePropName='checked'>
              <Switch />
            </Form.Item>
          </div>
          <div className='col-md-4'>
            <Form.Item
              label='Carbon Dioxide Removed'
              name={"carbonDioxideRemoved"}
              valuePropName='checked'>
              <Switch />
            </Form.Item>
          </div>
          <div className='w-100'></div>
          <div className='col-md-4'>
            <Form.Item
              label='Volunteering Opportunities'
              name={"volunteeringOpportunities"}
              valuePropName='checked'>
              <Switch />
            </Form.Item>
          </div>
          <div className='col-md-4'>
            <Form.Item
              label='Number Of Benefits'
              name={"numberOfBenefits"}
              valuePropName='checked'>
              <Switch />
            </Form.Item>
          </div>
          <div className='w-100'></div>
          <div className='col-md-4'>
            <Form.Item
              label='Total Donated'
              name={"totalDonated"}
              valuePropName='checked'>
              <Switch />
            </Form.Item>
          </div>
          <div className='col-md-4'>
            <Form.Item
              label='Donated to Carbon Removal'
              name={"donatedToCarbonRemoval"}
              valuePropName='checked'>
              <Switch />
            </Form.Item>
          </div>
          <div className='w-100'></div>
          <div className='col-md-4'>
            <Form.Item
              label='Donated to Climate Advocacy'
              name={"donatedToClimateAdvocacy"}
              valuePropName='checked'>
              <Switch />
            </Form.Item>
          </div>
          <div className='col-md-4'>
            <Form.Item
              label='Carbon Footprint'
              name={"carbonFootprint"}
              valuePropName='checked'>
              <Switch />
            </Form.Item>
          </div>
          <div className='w-100'></div>

          <div className='col-md-3'>
            <Form.Item
              {...formItemLayout}
              name='companyName'
              label='Company Name'>
              <Input className={style.input} />
            </Form.Item>
          </div>
          <div className='col-md-3'>
            <Popover content={contentBlockBack} trigger='click'>
              <Form.Item
                {...formItemLayout}
                label='Data Block Background Color'>
                <Input
                  readOnly
                  value={blockBackgroundColor}
                  className={style.input}
                />
              </Form.Item>
            </Popover>
          </div>
          <div className='w-100'></div>
          <div className='col-md-3'>
            <Popover content={content} trigger='click'>
              <Form.Item {...formItemLayout} label='Background Color'>
                <Input
                  readOnly
                  value={backgroundColor}
                  className={style.input}
                />
              </Form.Item>
            </Popover>
          </div>
          <div className='col-md-3'>
            <Popover content={contentText} trigger='click'>
              <Form.Item {...formItemLayout} label='Text Color'>
                <Input readOnly value={textColor} className={style.input} />
              </Form.Item>
            </Popover>
          </div>
          <div className='w-100 mt-4'></div>
          <div className='col-md-3'>
            {logoFile ? (
              <img
                src={logoFile}
                style={{
                  width: 100,
                  height: 100,
                  marginLeft: "25%",
                  marginBottom: 30,
                }}
                alt=''
              />
            ) : (
              ""
            )}
            <label htmlFor=''>
              <Button
                icon={<UploadOutlined />}
                onClick={() => fileRef.current.click()}>
                Click to upload Company Logo
              </Button>
              <input
                type='file'
                name='logo'
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    setLogo(e.target.files[0]);

                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(e.target.files[0]);
                    fileReader.addEventListener("load", function () {
                      setLogoFile(this.result);
                    });
                  }
                }}
                ref={fileRef}
                style={{ display: "none" }}
              />
            </label>
          </div>
        </div>
        <div className='col-12 ml-5 mt-5'>
          {loading ? <Spin /> : <button className='primaryBtn'>Save</button>}
        </div>
      </Form>
    </div>
  );
}
