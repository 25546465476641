import Types from "../constants/benefits";

var defaultState = {
  benefits: [],
  active_benefit: null,
  leaderboard: null,
  externalReport: null,
  tags: [],
  alerts: [],
  active_service: null,
  employerSettings: {},
};

var authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.SET_EMPLOYER_SETTINGS: {
      let newState = Object.assign({}, state);
      newState.employerSettings = action.payload;
      return newState;
    }

    case Types.ALERTS_GET: {
      let newState = Object.assign({}, state);
      newState.alerts = action.payload;
      return newState;
    }

    case Types.TAGS_GET: {
      let newState = Object.assign({}, state);
      newState.tags = action.payload;
      return newState;
    }

    case Types.REPORT_GET: {
      let newState = Object.assign({}, state);
      newState.externalReport = action.payload;
      return newState;
    }

    case Types.SET_LEADERBOARD: {
      let newState = Object.assign({}, state);
      newState.leaderboard = action.payload;
      return newState;
    }

    case Types.SET_BENEFITS: {
      let newState = Object.assign({}, state);
      newState.benefits = action.payload;
      return newState;
    }

    case Types.SET_ACTIVE_SERVICE: {
      let newState = Object.assign({}, state);
      newState.active_service = action.payload;
      return newState;
    }

    case Types.SET_ACTIVE_BENEFIT: {
      let newState = Object.assign({}, state);
      newState.active_benefit = action.payload;
      return newState;
    }

    case Types.ADD_BENEFIT: {
      let newState = Object.assign({}, state);
      newState.benefits = [...newState.benefits, action.payload];
      return newState;
    }

    default:
      return state;
  }
};
export default authReducer;
