import React from "react";
import { useSelector } from "react-redux";
import style from "../style/ClientBenefits.module.css";

export default function BenefitFeedbackCard() {
  const { benefitFeedbacks } = useSelector(
    (state) => state.auth.benefitFeedback
  );
  return (
    <div className='d-flex justify-content-start mb-5 mt-3 '>
      <table className={style.subscription_table}>
        <colgroup>
          <col width='10%' />
          <col width='25%' />
          <col width='25%' />
          <col width='25%' />
        </colgroup>
        <thead>
          <tr>
            <th>#</th>
            <th>Benefit Name</th>
            <th>Employee Name</th>
            <th>Rating</th>
            <th>Feedback</th>
          </tr>
        </thead>
        <tbody>
          {benefitFeedbacks.map(
            ({ score, feedback, benefit, employee, createdAt }, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{benefit.title}</td>
                <td>{employee.fullName}</td>
                <td>{score}</td>
                <td>{feedback}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
}
