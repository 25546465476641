import React, { useState, useEffect } from "react";
import { Input, Form, Modal, Radio, Switch, Spin, message } from "antd";
import benefitstyle from "../style/ClientBenefits.module.css";
import apiRequest from "../api/apiRequest";
import CreditCardModal from "./modal/CreditCardModal";
import { useSelector, useDispatch } from "react-redux";
export default function AddBenefit({ visible, setVisible, onFinish, plan }) {
  //states
  const [loading, setLoading] = useState(false);
  const [getCouponLoading, setGetCouponLoading] = useState(false);
  const [numberOfEmployees, setNumberOfEmployees] = useState("");
  const [numberOfEmployeesEPlan, setNumberOfEmployeesEPlan] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponCodeValue] = useState(0);
  const cardModal = useSelector((state) => state.cardModal?.openCardModal);

  const paymentMethod = useSelector(
    (state) => state?.auth?.user?.paymentMethods
  );
  const dispatch = useDispatch();
  //plan submision
  const stPlanSubmit = async () => {
    if (!numberOfEmployees) {
      message.error("Please enter number of employees");
      return;
    }
    if (paymentMethod && paymentMethod.length > 0) {
      setLoading(true);
      await onFinish({
        numberOfEmployees: numberOfEmployees,
        planType: plan,
        coupon: "",
      });
      setLoading(false);
    } else {
      dispatch({ type: "OPEN_CARD_MODAL" });
    }
  };

  const epPlanSubmit = async () => {
    if (!couponValue) {
      message.error("Please enter a Valid Custom Code");
      return;
    }

    if (paymentMethod && paymentMethod.length > 0) {
      setLoading(true);
      await onFinish({
        numberOfEmployees: numberOfEmployeesEPlan,
        planType: plan,
        coupon: couponCode,
      });
      setLoading(false);
    } else {
      dispatch({ type: "OPEN_CARD_MODAL" });
    }
  };

  //reset forms functions
  const onReset = () => {
    setNumberOfEmployees("");
    setCouponCode("");
  };

  var cost = 0;
  if (numberOfEmployees > 0 && numberOfEmployees <= 99) {
    var cost = 149;
  } else if (numberOfEmployees > 99 && numberOfEmployees <= 499) {
    var cost = 499;
  } else if (numberOfEmployees > 499 && numberOfEmployees <= 999) {
    var cost = 999;
  }
  const getCouponCodeValue = async (couponCode) => {
    setGetCouponLoading(true);
    await apiRequest({
      method: "get",
      url: `/coupon/${couponCode}`,
    })
      .then((res) => {
        setCouponCodeValue(res?.data?.data?.amount);
        setNumberOfEmployeesEPlan(
          res?.data?.data?.numberOfEmployees?.toString()
        );
        setGetCouponLoading(false);
      })
      .catch((err) => {
        setCouponCodeValue(0);
        setGetCouponLoading(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      getCouponCodeValue(couponCode);
    }, 1000);
  }, [couponCode]);

  const planPrice = plan == "starter" ? 5 : 4.5;

  return (
    <>
      <Modal
        title={plan == "starter" ? "Starter Plan" : "Enterprise Plan"}
        visible={visible}
        onOk={() => {}}
        onCancel={() => {
          setVisible(false);
          onReset();
        }}
        width={700}
      >
        <div className="row">
          <div className={`col-md-6`}>
            <Form.Item>
              {plan == "starter" ? (
                <Input
                  style={{
                    width: "440px",
                  }}
                  placeholder="How many employees do you have?"
                  min={0.5}
                  type="number"
                  className={benefitstyle.input}
                  value={numberOfEmployees}
                  onChange={(e) => setNumberOfEmployees(e.target.value)}
                />
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "440px",
                    }}
                  >
                    <Input
                      placeholder="Enter Custom Code"
                      min={0.5}
                      className={benefitstyle.input}
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                    {getCouponLoading ? (
                      <div className="ml-3 my-3 mr-0">
                        <Spin />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )}
            </Form.Item>
          </div>
          <div className="w-100"></div>
          <div className={`col-md-6`}>
            <p style={{ width: "auto" }}>
              {numberOfEmployees > 999 ? (
                <span
                  style={{
                    wordSpacing: "3px",
                  }}
                >
                  Contact us at{" "}
                  <span style={{ color: "#175cff ", fontWeight: "bolder" }}>
                    sales@climatebenefits.com
                  </span>
                </span>
              ) : (
                `Amount : ${
                  plan == "starter"
                    ? `$${cost} / month`
                    : `$${couponValue}  / Year`
                }`
              )}
            </p>
          </div>

          <div className="col-md-12">
            {loading ? (
              <div className="ml-3 my-3 mr-0">
                <Spin />
              </div>
            ) : numberOfEmployees > 999 ? (
              <button
                className="primaryBtn"
                style={{ opacity: "0.7", cursor: "not-allowed" }}
                disabled
              >
                Pay Now
              </button>
            ) : (
              <button
                type="submit"
                className="primaryBtn"
                onClick={() =>
                  plan == "starter" ? stPlanSubmit() : epPlanSubmit()
                }
              >
                Pay Now
              </button>
            )}
          </div>
        </div>
      </Modal>
      {cardModal ? <CreditCardModal onPlanSubmit={true} /> : ""}
      {/* {paymentMethod && paymentMethod.length > 0 ? "" :} */}
    </>
  );
}
