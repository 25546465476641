import { Tabs } from "antd";
import React from "react";
import APIKeys from "./APIKeys";
import APILogs from "./APILogs";
import Docs from "./Docs";
const { TabPane } = Tabs;

export default function Developer() {
  return (
    <div>
      <Tabs>
        <TabPane tab="API Keys" key="0">
          <APIKeys />
        </TabPane>
        <TabPane tab="API Logs" key="1">
          <APILogs />
        </TabPane>
        <TabPane tab="API Docs" key="2">
          <Docs />
        </TabPane>
      </Tabs>
    </div>
  );
}
