import React, { useState, useEffect } from "react";
import styles from "../style/subscriptionPlan.module.css";
import style from "../style/ClientBenefits.module.css";
import SubscriptionNumber from "./SubscriptionNumber";
import { changeLeaderboard } from "../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Form,
  Input,
  Switch,
  Tooltip,
  message,
  Upload,
  Button,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { InfoCircleOutlined, DollarCircleOutlined } from "@ant-design/icons";

export default function LeaderBoard() {
  const user = useSelector((state) => state.auth.user);
  const [form] = Form.useForm();
  const [points, setPoints] = useState(user?.details?.points);
  const [leaderboard, setLeaderboard] = useState(user?.details?.leaderboard);
  const [tutorial, setTutorial] = useState(user?.details?.tutorial);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [brand, setBrand] = useState(false);
  const dispatch = useDispatch();
  const [fileChange, setFileChange] = useState("");

  useEffect(() => {
    if (user.details.brandName) {
      setBrand(true);
      form.setFieldsValue({
        brandName: user.details.brandName,
      });
    }
    if (user.details.tutorial) {
      setTutorial(true);
    }
    if (user.details.points) {
      setPoints(true);
    }
    if (user.details.leaderboard) {
      setLeaderboard(true);
    }
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    let tempObject = {
      points,
      leaderboard,
      tutorial,
      brandName: values.brandName,
    };

    if (fileChange) tempObject["image"] = fileChange;

    if (!brand) {
      tempObject.brandName = "";
      tempObject["image"] = "";
    }

    const result = await dispatch(changeLeaderboard(tempObject));

    if (result) {
      Modal.success({
        content: "Your engagement settings have been saved!",
      });
    }
    setLoading(false);
  };

  const onFinishFailed = () => {};

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  return (
    <div>
      <div>
        <Form
          className="row"
          style={{
            width: "100%",
            maxWidth: "700px",
            marginBottom: "2em",
          }}
          name="basic"
          initialValues={{
            remember: true,
          }}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className={`col-md-12`}>
            <Form.Item label="Points" name={"points"}>
              <div>
                <Switch
                  style={{ marginRight: "1em" }}
                  defaultChecked={points}
                  onChange={setPoints}
                />
                <Tooltip title="By turning on Points, Employees will receive 1 point for every dollar contributed through Climate Benefits. This includes both Employer and Employee contributions.">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              </div>
            </Form.Item>
          </div>
          <div className={`col-md-12`}>
            <Form.Item label="Leaderboard" name={"leaderboard"}>
              <div>
                <Switch
                  style={{ marginRight: "1em" }}
                  defaultChecked={leaderboard}
                  onChange={setLeaderboard}
                />
                <Tooltip title="By turning on the Leadrboard, Employees can track their activity against their team members on the home page.">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              </div>
            </Form.Item>
          </div>

          <div className={`col-md-12`}>
            <Form.Item label="Tutorial" name={"tutorial"}>
              <div>
                <Switch
                  style={{ marginRight: "1em" }}
                  checked={tutorial}
                  onChange={setTutorial}
                />
              </div>
            </Form.Item>
          </div>

          <div className={`col-md-12`}>
            <Form.Item label="Branding" name={"brand"}>
              <div>
                <Switch
                  style={{ marginRight: "1em" }}
                  checked={brand}
                  onChange={setBrand}
                />
              </div>
            </Form.Item>
          </div>

          {brand && (
            <>
              <div className="col-md-12 ">
                <div className="col-md-6 " style={{ marginLeft: "-13px" }}>
                  <Form.Item
                    label=""
                    name="brandName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your First Name!",
                      },
                    ]}
                  >
                    <Input
                      className="brandNameField"
                      placeholder="Brand Name"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="col-md-6 ">
                <Form.Item
                  label=""
                  rules={[
                    {
                      required: true,
                      message: `Please select an image`,
                    },
                  ]}
                >
                  <input
                    type="file"
                    className={style.input}
                    onChange={(e) =>
                      e.target.files ? setFileChange(e.target.files[0]) : ""
                    }
                  />
                </Form.Item>
              </div>
            </>
          )}
          <div className="col-12">
            {loading ? (
              <Spin />
            ) : (
              <button className="primaryBtn" type="submit">
                Save
              </button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
}
