import React, { useState } from "react";
import style from "../style/integeration.module.css";
import { useFinchConnect } from "react-finch-connect";
import environment from "../api/environment";
import apiRequest from "../api/apiRequest";
import { finchConnect } from "../redux/actions/auth";
import { connect } from "react-redux";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { InfoCircleOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { InputNumber, Tooltip } from "antd";

const mapDispatch = (dispatch) => ({
  finchConnect: (data) => dispatch(finchConnect(data)),
});

function Integration(props) {
  const history = useHistory();
  const payment = useSelector((state) => state.auth.user.paymentMethods);
  const subscription = useSelector((state) => state.auth.user.subscription);
  const [isModalVisible, setModalVisible] = useState(false);

  const { availSeats } = props;
  const onSuccess = async ({ code }) => {
    await props.finchConnect(code);
    Modal.success({
      content: "Payroll Provider integrated successfully.",
    });
    history.push("/employer/teams");
  };
  const onError = ({ errorMessage }) =>
    Modal.error({
      title: "Payroll Provider integration error",
      content: errorMessage,
    });
  const onClose = () => {
    // console.log("User exited")
  };

  const { open } = useFinchConnect({
    clientId: environment.finchId,
    // payrollProvider: '<payroll-provider-id>',
    products: ["company", "directory", "individual"],
    onSuccess,
    onError,
    onClose,
  });
  return (
    <>
      <Modal
        footer={null}
        title='Workflow Integration'
        visible={isModalVisible}
        onOk={() => {
          setModalVisible(false);
        }}
        onCancel={() => setModalVisible(false)}
        width={700}>
        <zapier-app-directory app='climate-benefits' theme='light' />
      </Modal>
      {props.page == "team" ? (
        <>
          <div className='mr-3'>
            <Tooltip title="You can integrate with your Payroll or HR system to automatically mass import employee data. This integration is secure and we'll tell you exactly the data we're collecting before establishing the connection.">
              <InfoCircleOutlined style={{ color: "black" }} />
            </Tooltip>
          </div>
          <button
            style={{ width: "220px" }}
            onClick={() => {
              if (payment && payment.length > 0) {
                if (subscription.type == "starter") open();
                else {
                  if (availSeats == 0)
                    Modal.info({
                      content:
                        "You're out of paid seats under the Enterprise tier. You can continue adding team members— we'll update your subscription and charge the card on file.",
                      okText: "Confirm",
                      onOk: () => {
                        open();
                      },
                    });
                  else open();
                }
              } else
                Modal.error({
                  content:
                    "Please add a payment method before adding employees",
                  okText: "Add Now",
                  onOk: () => {
                    history.push("/employer/settings");
                  },
                });
            }}
            className={`primaryBtn mt-0 d-flex align-items-center`}>
            <span>Import Employee Data</span>
          </button>
        </>
      ) : (
        <div className={style.Integration}>
          <div>
            <span style={{ marginRight: "5em", fontSize: "1.3em" }}>
              Payroll Provider
            </span>
            <button onClick={() => open()} className={`primaryBtn`}>
              Connect
            </button>
          </div>

          <div>
            <span style={{ marginRight: "5em", fontSize: "1.3em" }}>
              Workflow Integrations
            </span>
            <button
              onClick={() => setModalVisible(true)}
              className={`primaryBtn`}>
              Connect
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default connect(null, mapDispatch)(Integration);
