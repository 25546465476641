export default {
  SET_BENEFITS: "sets the benefits",
  SET_ACTIVE_BENEFIT: "sets active benefit",
  ADD_BENEFIT: "adds a new benefit",
  SET_LEADERBOARD: "SET_LEADERBOARD",
  REPORT_GET: "REPORT_GET",
  TAGS_GET: "TAGS_GET",
  ALERTS_GET: "ALERTS_GET",
  SET_ACTIVE_SERVICE: "SET_ACTIVE_SERVICE",
  SET_EMPLOYER_SETTINGS: "SET_EMPLOYER_SETTINGS",
};
