import React from "react";
import style from "../../style/signupForm.module.css";
import { Steps } from "antd";
import PaymentMethod from "./PaymentMethod";
import PricePlan from "./PricePlan";
import Questions from "./Questions";
import { useHistory } from "react-router-dom";
import { signUp, sendRegisterNotification } from "../../redux/actions/auth";
import apiRequest from "../../api/apiRequest";

const { Step } = Steps;

export default function AuthSteps() {
  const [current, setCurrent] = React.useState(0);
  const [answerOne, setAnswerOne] = React.useState(1);
  const [answerTwo, setAnswerTwo] = React.useState(1);
  const [answerThree, setAnswerThree] = React.useState("");
  const history = useHistory();

  const getAnswersOne = (answer) => setAnswerOne(answer);
  const getAnswersTwo = (answer) => setAnswerTwo(answer);
  const getAnswersThree = (answer) => setAnswerThree(answer);

  const steps = [
    {
      title: "Add Payment Method",
      content: <PaymentMethod />,
    },
    {
      title: "Choose a Plan",
      content: <PricePlan />,
    },
    {
      title: "Personalize Your Experience",
      content: (
        <Questions
          getAnswersOne={getAnswersOne}
          getAnswersTwo={getAnswersTwo}
          getAnswersThree={getAnswersThree}
        />
      ),
    },
  ];

  const data = {
    questionOne:
      "On a scale from 1-10 how environmentally conscious is your company?",
    answerOne: answerOne,
    questionTwo:
      "On a scale from 1-10 how environmentally conscious are you personally?",
    answerTwo: answerTwo,
    questionThree:
      "Does your company have any sustainability or climate change initiatives currently?",
    answerThree: answerThree,
  };

  const saveQnA = async () => {
    await apiRequest({
      method: "POST",
      url: "/question",
      data: data,
    })
      .then((res) => res)
      .catch((err) => err);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <div className={style.signInForm}>
      <div className={"d-flex justify-content-end w-100"}>
        <p
          onClick={() => {
            sendRegisterNotification();
            history.push(`/${window.location.pathname.split("/")[2]}`);
          }}
          style={{ cursor: "pointer", position: "absolute", top: "35px" }}>
          Skip
        </p>
      </div>
      <Steps size='small' current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <div style={{ marginTop: "100px" }}>{steps[current].content}</div>

      <div className='steps-action d-flex justify-content-between w-100 mt-0'>
        {current > 0 ? (
          <button
            className='primaryBtn align-self-center '
            onClick={() => prev()}>
            Previous
          </button>
        ) : (
          <div></div>
        )}

        {current < steps.length - 1 && (
          <button
            className='primaryBtn align-self-center '
            onClick={() => next()}>
            Next <img src='/images/nextIcon.svg' alt='' />
          </button>
        )}
        {current === steps.length - 1 && (
          <button
            className='primaryBtn align-self-center '
            onClick={() => {
              saveQnA();
              sendRegisterNotification();
              history.push(`/${window.location.pathname.split("/")[2]}`);
            }}>
            Done
          </button>
        )}
      </div>
    </div>
  );
}
