export const nftCardsData = [
  {
    id: "1",
    title: "Nft-1",
    image: "img-1.png",
  },
  {
    id: "1",
    title: "Nft-2",
    image: "img-2.png",
  },

  {
    id: "1",
    title: "Nft-3",
    image: "img-3.png",
  },
  {
    id: "1",
    title: "Nft-4",
    image: "img-4.png",
  },
  {
    id: "2",
    title: "Nft-5",
    image: "background-img.png",
  },
  {
    id: "2",
    title: "Nft-6",
    image: "background-img.png",
  },
  {
    id: "2",
    title: "Nft-7",
    image: "background-img.png",
  },
  {
    id: "2",
    title: "Nft-8",
    image: "background-img.png",
  },
];
