import React, { useState } from "react";
import style from "../style/sidebar.module.css";
import { NavLink } from "react-router-dom";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import { useSelector, useDispatch } from "react-redux";

export default function Sidebar({ sidebarData, sidebarVisible }) {
  const introToggle = useSelector((state) => state?.intro?.openIntro);

  const userType = useSelector((state) => state?.auth?.user?.type);
  var steps = [];
  if (userType == "employer") {
    steps = [
      {
        element: ".home",
        intro:
          "Welcome! From the Home page, you see an overview of your Climate Benefits program. We update the metrics on this page automatically as you use the platform.",
        position: "right",
      },
      {
        element: ".measure",
        intro:
          "On the Measure page, you can calculate and forecast your company's carbon emissions, aligned with Scope 2 and 3 emissions.",
        position: "right",
      },
      {
        element: ".benefits",
        intro:
          "The Benefits page is our marketplace of vetted climate change partners. Partners include carbon removal projects, advocacy organizations, volunteering opportunities, and eco-friendly products & services. You can also add your own internal initiatives as well.",
        position: "right",
      },
      {
        element: ".team",
        intro:
          "On the Team page, you can manage your team members participating in Climate Benefits. Invite team members, assign admin rights, and track data across your organization.",
        position: "right",
      },
      {
        element: ".communication",
        intro:
          "On the Communication page, you can customize all communication touch points with your team. This includes the About page content (i.e. a page employees see when they log in to their dashboard), email invitations, and external reports.",
        position: "right",
      },
      {
        element: ".reports",
        intro:
          "On the Reports page, you can view all data related to your climate change impact and feedback from team members. Additionally, you can create Sustainability Alerts and export Tax Forms.",
        position: "right",
      },
      {
        element: ".support",
        intro:
          "On the Support page, you can view all forms of Climate Benefits support based on your subscription tier. Submit support tickets, view product documentation, and more. We're always here to help.",
        position: "right",
      },
      {
        element: ".settings",
        intro:
          "On the Settings page, you can view and edit your Account Information, set up integrations, and track subscription payments. Additionally, we provide a suite of engagement features that you can turn on or off depending on your goals.",
        position: "right",
      },
    ];
  } else if (userType == "employee") {
    steps = [
      {
        element: ".home",
        intro:
          "Welcome! From the Home page, you see an overview of your company's Climate Benefits program. We update the metrics on this page automatically as you use the platform.",
        position: "right",
      },
      {
        element: ".about",
        intro:
          "On the About page, you see a description of your company’s Climate Benefits program. The Admin can change this content dynamically so revisit this page for updates on your stipend, new benefit providers, and more. ",
        position: "right",
      },
      {
        element: ".benefits",
        intro:
          "The Benefits page is our marketplace of vetted climate change partners. Partners include carbon removal projects, advocacy organizations, volunteering opportunities, and eco-friendly products & services. Your company may add their own initiatives as well. You can use your monthly stipend to combat climate change directly. ",
        position: "right",
      },
      {
        element: ".resources",
        intro:
          "On the Resources page, we provide vetted resources about climate change, sustainability, reporting standards, and more. We're always adding more engaging content.",
        position: "right",
      },
      {
        element: ".support",
        intro:
          "On the Support page, you can view all forms of Climate Benefits support based on your company's subscription tier. Submit support tickets, view product documentation, and more. We're always here to help. ",
        position: "right",
      },
      {
        element: ".settings",
        intro:
          "On the Settings page, you can view and edit your Account Information.",
        position: "right",
      },
    ];
  }
  const dispatch = useDispatch();
  return (
    <>
      <Steps
        enabled={introToggle}
        steps={steps}
        initialStep={0}
        onExit={() => {
          dispatch({ type: "CLOSE_INTRO" });

          if (document.querySelector(".mainContent"))
            document.querySelector(".mainContent").style.marginTop = 0;

          setTimeout(() => {
            if (document.querySelector(".mainContent"))
              document.querySelector(".mainContent").style.marginTop = "3.9em";
          }, 100);
        }}
      />

      <div
        className={`Sidebar ${style.Sidebar} ${
          sidebarVisible ? style.show : ""
        }`}
      >
        {sidebarData.map((item, i) => (
          <NavLink to={item.link} exact className={item.menuClass}>
            <img
              src={`/images/${item.icon}`}
              style={{ width: "18px" }}
              alt=""
            />
            {item.title}
          </NavLink>
        ))}
      </div>
    </>
  );
}
