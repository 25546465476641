import Types from "../constants/service";
var defaultState = {
  services: [],
};

const serviceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.GET_SERVICES: {
      let newState = Object.assign({}, state);
      newState.services = action.payload;
      return newState;
    }
    default:
      return state;
  }
};
export default serviceReducer;
