import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { useSelector } from "react-redux";

export default function CreditCard(props) {
  const payment = useSelector((state) => state.auth.user.paymentMethods);
  const user = useSelector((state) => state.auth.user);
  if (payment.length > 0) {
    const { exp_month, exp_year, last4 } = payment[0].card;
    // const { name } = payment[0].billing_details;
    const ccnumber = `************${last4}`;
    return (
      <div>
        <Cards
          style={{ margin: 0 }}
          preview={true}
          cvc='123'
          expiry={`${exp_month}/${exp_year}`}
          name={`${user.firstName} ${user.lastName}`}
          number={ccnumber}
        />

        <button
          className='primaryBtn ml-auto'
          type='submit'
          onClick={() => props.onEdit()}>
          Edit
        </button>
      </div>
    );
  } else {
    return "";
  }
}
