import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import DashboardLayout from "../pages/DashboardLayout";
import employerData from "../data/employer.json";
import employeeData from "../data/employee.json";
import providerData from "../data/provider.json";
import {
  setUser,
  fetchEmployees,
  fetchDashboardData,
  fetchBenefitFeedback,
  getCurrency,
} from "../redux/actions/auth";
import { fetchResources } from "../redux/actions/resources";
import {
  fetchBenefits,
  fetchLeaderBoard,
  getAlertTags,
  fetchReport,
  getAlerts,
  fetchEmployerSettings,
} from "../redux/actions/benefits";
import { fetchServices } from "../redux/actions/service";
import { getTemplate, getTickets } from "../redux/actions/dashboard";
import { connect } from "react-redux";

const mapDispatch = (dispatch) => ({
  setUser: (data) => dispatch(setUser(data)),
  fetchBenefits: () => dispatch(fetchBenefits()),
  fetchServices: () => dispatch(fetchServices()),
  fetchEmployees: () => dispatch(fetchEmployees()),
  fetchResources: () => dispatch(fetchResources()),
  fetchDashboardData: () => dispatch(fetchDashboardData()),
  fetchBenefitFeedback: () => dispatch(fetchBenefitFeedback()),
  getTemplate: () => dispatch(getTemplate()),
  fetchLeaderBoard: () => dispatch(fetchLeaderBoard()),
  getCurrency: (val) => dispatch(getCurrency(val)),
  getAlertTags: () => dispatch(getAlertTags()),
  fetchReport: () => dispatch(fetchReport()),
  getAlerts: () => dispatch(getAlerts()),
  getTickets: () => dispatch(getTickets()),
  fetchEmployerSettings: () => dispatch(fetchEmployerSettings()),
});

const mapState = (state) => ({
  user: state.auth.user,
});

function DashboardRoutes(props) {
  useEffect(() => {
    props.fetchBenefits();
    props.fetchServices();
    props.fetchResources();
    if (props.user.type == "employer") {
      props.fetchEmployees();
      props.fetchBenefitFeedback();
      props.getTemplate();
      props.getAlertTags();
      props.fetchReport();
      props.getAlerts();
      props.getTickets();
    } else if (props.user.type == "employee") {
      props.fetchLeaderBoard();
      props.fetchEmployerSettings();
    }

    props.fetchDashboardData();
  }, []);

  useEffect(() => {
    if (props.user.details.currency)
      props.getCurrency(props.user.details.currency);
    else props.getCurrency(false);
  }, [props.user]);

  return (
    <Switch>
      <Route
        path="/employer"
        render={() => <DashboardLayout Data={employerData} />}
      />
      <Route
        path="/employee"
        render={() => <DashboardLayout Data={employeeData} />}
      />
      <Route
        path="/benefitprovider"
        render={() => <DashboardLayout Data={providerData} />}
      />
    </Switch>
  );
}

export default connect(mapState, mapDispatch)(DashboardRoutes);
