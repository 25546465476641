import React, { useState, useEffect } from "react";
import { Select, Input, Button, Form, Modal, message } from "antd";
import style from "../style/teams.module.css";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import _ from "lodash";
import { addEmployee, inviteEmployee } from "../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { deleteEmployee } from "../redux/actions/auth";
import Integration from "../components/Integration";
import { useHistory } from "react-router";
import AddEmployee from "../components/AddEmployee";
import EditEmployee from "../components/EditEmployee";
import { editEmployee, makeAdmin } from "../redux/actions/auth";
import { Checkbox } from "antd";
import { ConvertCurrency } from "../utils";
import Upgrade from "../components/modal/Upgrade";
const { Option } = Select;

const mapState = (state) => ({
  employees: state.auth.employees,
});

const dispatchState = (dispatch) => ({
  addEmployee: (data) => dispatch(addEmployee(data)),
  inviteEmployee: (data) => dispatch(inviteEmployee(data)),
});

function Teams({ employees, addEmployee, inviteEmployee }) {
  const [searchVal, setSearchVal] = useState("");
  const [order, setOrder] = useState("");
  const [upgradeModal, setUpgradeModal] = React.useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [employeeList, setEmployeeList] = useState(employees);
  const [editModal, setEditModal] = useState(false);
  const payment = useSelector((state) => state.auth.user.paymentMethods);
  const user = useSelector((state) => state.auth.user);
  const subscription = useSelector((state) => state.auth.user.subscription);
  const dispatch = useDispatch();
  const history = useHistory();
  let rates = useSelector((state) => state.auth.currencyRates.rates);

  useEffect(() => {
    setEmployeeList(employees);
  }, [employees]);

  const onFinish = async (values) => {
    const result = await addEmployee(values);
    if (result) setModalVisible(false);
  };

  const onUserEdit = async (values) => {
    const result = await dispatch(editEmployee(values));
    if (result) setEditModal(false);

    return;
  };

  const onInvite = async (id) => {
    inviteEmployee({ employeeids: [id] });
    message.success(
      "Email invitation has been sent. This can take a few minutes to process."
    );
  };

  let tempEmployees = employeeList;
  tempEmployees = _.uniqBy(tempEmployees, "_id");

  if (searchVal)
    tempEmployees = tempEmployees.filter((item) =>
      item.fullName.includes(searchVal)
    );
  if (order && order !== "All")
    tempEmployees = tempEmployees.filter((item) => item.status == order);

  return (
    <div>
      <div
        className={`d-lg-flex align-items-center justify-content-between flex-wrap mb-3 ${style.ClientBenefits}`}
      >
        <h1
          className="mb-0"
          style={{
            fontFamily: "'Inter', sans-serif",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "26px",
            lineHeight: "31px",
            color: "#0A083B",
            margin: "0",
          }}
        >
          Team
        </h1>

        <AddEmployee
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          onFinish={onFinish}
        />

        <EditEmployee
          isModalVisible={editModal}
          setModalVisible={setEditModal}
          onFinish={onUserEdit}
        />

        <div
          className="d-lg-flex align-items-center justify-content-end flex-wrap mt-5 mt-lg-0"
          style={{ flex: 1 }}
        >
          <Integration
            page="team"
            availSeats={
              subscription.type !== "trial"
                ? subscription.numberOfEmployees - tempEmployees.length
                : 0
            }
          />
          <button
            className="primaryBtn mx-sm-3 my-2 my-lg-0"
            style={{ width: "240px" }}
            onClick={() => {
              if (payment && payment.length > 0) {
                if (subscription.type == "trial") setModalVisible(true);
                else {
                  if (
                    subscription.numberOfEmployees - tempEmployees.length ==
                    0
                  )
                    Modal.info({
                      content: `You're out of paid seats under the ${subscription.type} tier. You can continue adding team members— we'll update your subscription and charge the card on file.`,
                      okText: "Confirm",
                      onOk: () => {
                        setModalVisible(true);
                      },
                    });
                  else setModalVisible(true);
                }
              } else
                Modal.error({
                  content:
                    "Please add a payment method before adding employees",
                  okText: "Add Now",
                  onOk: () => {
                    setUpgradeModal(true);
                  },
                });
            }}
          >
            Add Employees Manually
          </button>
          <Upgrade
            upgradeModal={upgradeModal}
            setUpgradeModal={setUpgradeModal}
          />

          <Input
            placeholder="Search all team members"
            prefix={<SearchOutlined />}
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            className="h-100 mb-2 mb-lg-0"
            style={{ width: "100%", maxWidth: "300px" }}
          />
          <Select
            defaultValue="Sort By"
            style={{ width: "120px", marginLeft: "0.5em" }}
            onChange={(val) => setOrder(val)}
          >
            <Option value="All">All</Option>
            <Option value="Not Invited">Not Invited</Option>
            <Option value="Pending">Pending</Option>
            <Option value="Accepted">Accepted</Option>
          </Select>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <a
          className={`${style.mainBtn} mr-3 mb-4`}
          style={{
            backgroundColor: "#dc3545",
            opacity: selectAllChecked ? 1 : 0,
          }}
          href="#"
          onClick={() =>
            dispatch(
              deleteEmployee(
                employeeList
                  .filter((item) => item.checked)
                  .map((item) => item._id)
              )
            )
          }
        >
          Delete
        </a>

        <a
          className={`${style.mainBtn} mr-md-3 mb-4 `}
          href="#"
          onClick={() =>
            inviteEmployee({
              employeeids: employeeList
                .filter((item) => item.checked)
                .map((item) => item._id),
            })
          }
        >
          Invite Selected
        </a>

        {subscription.type !== "trial" ? (
          <div className="d-flex justify-content-end">
            <p className="mr-5">
              Available Employee Seats :{" "}
              {subscription.numberOfEmployees - tempEmployees.length}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
      <table className={style.teamTable}>
        <colgroup>
          <col width="34%" />
          <col width="26%" />
          <col width="40%" />
        </colgroup>
        <thead>
          <tr>
            <th style={{ minWidth: "65px" }}>
              <Checkbox
                checked={selectAllChecked}
                onChange={(e) => {
                  setSelectAllChecked(e.target.checked);
                  setEmployeeList(
                    employeeList.map((ite) => {
                      ite["checked"] = e.target.checked;
                      return ite;
                    })
                  );
                }}
              ></Checkbox>
            </th>
            <th style={{ minWidth: "300px" }}>Name</th>
            <th>Email</th>
            <th style={{ minWidth: "210px" }}>Carbon Reduced</th>
            <th style={{ minWidth: "210px" }}>Amount Donated</th>
            <th>Invitation</th>
            <th style={{ minWidth: "530px" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tempEmployees.map((item) => (
            <tr>
              <td style={{ paddingRight: 0 }}>
                {" "}
                <Checkbox
                  onChange={(e) => {
                    let allItems = employeeList.map((ite) => {
                      if (ite._id == item._id)
                        ite["checked"] = e.target.checked;
                      return ite;
                    });
                    setEmployeeList(allItems);
                    if (allItems.filter((ite) => ite.checked).length == 0)
                      setSelectAllChecked(false);

                    if (
                      allItems.filter((ite) => ite.checked).length ==
                      allItems.length
                    )
                      setSelectAllChecked(true);
                  }}
                  checked={item.checked}
                ></Checkbox>
              </td>
              <td className={`${style.user} pl-0`}>
                <span className={style.userName}>
                  {item.firstName} {item.lastName}
                </span>
              </td>
              <td>
                <span>{item.email}</span>
              </td>
              <td>
                <span>{item.carbonReduced ? item.carbonReduced : 0} Tons </span>
              </td>
              <td>
                <span>
                  {ConvertCurrency(
                    user.details.currency,
                    rates,
                    item.amountContributedToDate
                      ? item.amountContributedToDate
                      : 0
                  )}
                </span>
              </td>
              <td>{item.status}</td>
              <td>
                {item.email == user.email ? (
                  ""
                ) : (
                  <>
                    {" "}
                    {item.status == "Not Invited" ? (
                      <a
                        className={`${style.mainBtn} mr-md-3 mb-2 mb-md-0`}
                        href="#"
                        onClick={() => onInvite(item._id)}
                      >
                        Invite
                      </a>
                    ) : item.status == "Pending" ? (
                      <a
                        className={`${style.mainBtn} mr-md-3 mb-2 mb-md-0`}
                        href="#"
                        onClick={() => onInvite(item._id)}
                      >
                        Re-Invite
                      </a>
                    ) : (
                      ""
                    )}
                    <>
                      {user.type == "employer" && !item.adminAccess ? (
                        <a
                          className={`${style.mainBtn} btn-info mr-2`}
                          href="#"
                          onClick={() => dispatch(makeAdmin(item._id))}
                        >
                          Make Admin
                        </a>
                      ) : (
                        ""
                      )}
                      <a
                        className={`${style.mainBtn} btn-info mr-2`}
                        href="#"
                        onClick={() => setEditModal(item)}
                      >
                        Edit
                      </a>

                      <a
                        className={style.mainBtn}
                        style={{ backgroundColor: "#dc3545" }}
                        href="#"
                        onClick={() => dispatch(deleteEmployee([item._id]))}
                      >
                        Delete
                      </a>
                    </>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default connect(mapState, dispatchState)(Teams);
