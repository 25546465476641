import React, { useState } from "react";
import {
  Select,
  Input,
  Button,
  Form,
  Modal,
  Radio,
  message,
  Space,
  Slider,
} from "antd";
import benefitstyle from "../../style/ClientBenefits.module.css";
import { sendFeedback } from "../../redux/actions/feedback";

export default function Feedback({ feedbackModal, setFeedbackModal }) {
  const { TextArea } = Input;

  const onFinish = async (values) => {
    sendFeedback(values);
  };

  return (
    <Modal
      title="Feedback"
      visible={feedbackModal}
      onOk={() => {}}
      onCancel={() => setFeedbackModal(false)}
    >
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <div className="row">
          <div className={`col-md-12`}>
            <Form.Item
              labelCol={{ span: 24 }}
              label="How would you feel if you could no longer use Climate Benefits?"
              name="feelings"
            >
              <Radio.Group className="my-4 d-flex flex-column align-items-start">
                <Radio className="mb-2" value={"Very disappointed"}>
                  Very disappointed
                </Radio>
                <Radio className="mb-2" value={"Some what disappointed"}>
                  Some what disappointed
                </Radio>
                <Radio className="mb-2" value={"Not disappointed"}>
                  Not disappointed
                </Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className={`col-md-12`}>
            <Form.Item
              labelCol={{ span: 24 }}
              label="On a scale from 0 - 10 how likely are you to recommend Climate Benefits to a friend or colleague?"
              name="recommendScore"
            >
              <Slider min={0} max={10} defaultValue={0} className="mt-5" />
            </Form.Item>
          </div>
          <div className={`col-md-12`}>
            <Form.Item
              labelCol={{ span: 24 }}
              label="How can we improve your experience?"
              name={"body"}
              rules={[
                {
                  required: true,
                  message: `Please enter your message`,
                },
              ]}
            >
              <TextArea rows={1} className={benefitstyle.input} />
            </Form.Item>
          </div>
          <div className="col-md-12">
            <button
              onClick={() => {
                setFeedbackModal(false);
              }}
              type="submit"
              className="primaryBtn"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
