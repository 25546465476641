const initialState = { openCardModal: false };

const cardModalReducer = (state = initialState, { type }) => {
  if (type == "OPEN_CARD_MODAL") {
    return { openCardModal: true };
  }
  if (type == "CLOSE_CARD_MODAL") {
    return { openCardModal: false };
  }
  return state;
};

export default cardModalReducer;
