import apiRequest from "../../api/apiRequest";
import Type from "../constants/service";
import { message } from "antd";

export const getServices = (data) => ({
  type: Type.GET_SERVICES,
  payload: data,
});

// actions
export const fetchServices = () => async (dispatch) => {
  return await apiRequest({
    method: "get",
    url: "/services/all",
  })
    .then((res) => {
      dispatch(getServices(res?.data?.data));
    })
    .catch((err) => {
      return err;
    });
};
