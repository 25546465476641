import React from "react";
import style from "../style/signupForm.module.css";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox } from "antd";
import { connect } from "react-redux";
import { signIn } from "../redux/actions/auth";
import { getToken, onMessageListener } from "../firebase";

import { useHistory } from "react-router-dom";

const mapDispatch = (dispatch) => ({
  signIn: (data) => dispatch(signIn(data)),
});

function SignInForm({ signIn }) {
  const history = useHistory();

  const onFinish = async (values) => {
    const token = await getToken();
    const result = await signIn({
      ...values,
      deviceToken: token ? token : "not",
    });

    if (result) history.push(`/${result}`);
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div className={style.signInForm}>
      <h2>Sign In</h2>
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="row">
          <div className="col-md-7 mx-auto">
            <Form.Item
              label=""
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </div>
          <div className="col-md-7 mx-auto">
            <Form.Item
              label=""
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input placeholder="Password" type="password" />
            </Form.Item>
          </div>
          <div className="col-md-7 mx-auto">
            <div className="d-flex flex-column align-items-center justify-content-between">
              <button type="submit" className="primaryBtn mb-4">
                Go to Dashboard
              </button>
              <Link to="/forgot">Forgot your password</Link>
            </div>
            <p className="text-center mt-4 mb-0">Don't have an account?</p>
            <p className="text-center mb-0 d-flex align-items-center justify-content-center">
              Sign up as an
              <Link to="/signup/employer" className="mx-2">
                {" "}
                Employer{" "}
              </Link>{" "}
              or a{" "}
              <Link to="/signup/benefitprovider" className="mx-2">
                {" "}
                Benefits Provider{" "}
              </Link>
            </p>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default connect(null, mapDispatch)(SignInForm);
