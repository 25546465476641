import React from "react";
import style from "./index.module.css";

import { Col, Row } from "react-bootstrap";

export const NftDownload = () => {
  return (
    <div className={style.nftDownload}>
      <div className={style.downloadBtn}>
        <img src="/images/download.svg" alt="" />
      </div>
      <Row>
        <Col md={6}>
          <div className={style.nftProfile}>
            <img src="/images/img-5.png" alt="" />
          </div>
        </Col>
        <Col md={6}>
          <div>
            <div className={style.checkmarks}>
              <span>Climate Benefits</span>
              <img src="/images/check-icon.svg" alt="" />
            </div>
            <h1>Climate Benefits NFT #7851</h1>
            <p className={style.ownedBtn}>
              Owned by <a href="">Climate Change</a>
            </p>
            <div className={style.descriptionCard}>
              <div className={style.cardTitle}>
                <h5>
                  <img src="/images/bars-icon.svg" alt="" />
                  Description
                </h5>
              </div>
              <div className={style.cardContent}>
                <p>
                  (NFT Name) was minted when (employee name) contributed
                  $(amount) to (partner name) to combat climate change.
                </p>
              </div>
            </div>
            <button className={style.yourNftBtn}>Claim your NFT</button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
