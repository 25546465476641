import React from "react";
import style from "../style/cardDetail.module.css";
import { useHistory } from "react-router-dom";
import environment from "../api/environment";
import { deleteBenefit } from "../redux/actions/benefits";
import { connect } from "react-redux";
import { Modal, message } from "antd";
import EditBenefit from "./EditBenefit";
// import { useSelector } from "react-redux";
import OffsetPurchase from "./OffsetPurchase";
import {
  addBenefitToEmployer,
  removeBenefitFromEmployer,
} from "../redux/actions/auth";
const mapState = (state) => ({
  benefits: state.benefits.benefits,
  user: state.auth.user,
});

const dispatchState = (dispatch) => ({
  deleteBenefit: (data) => dispatch(deleteBenefit(data)),
  addBenefitToEmployer: (data) => dispatch(addBenefitToEmployer(data)),
  removeBenefitFromEmployer: (data) =>
    dispatch(removeBenefitFromEmployer(data)),
});
function CardDetail({
  data,
  imgRoute,
  userType,
  deleteBenefit,
  addBenefitToEmployer,
  removeBenefitFromEmployer,
  page,
  user,
  benefits,
}) {
  const [isModalVisible, setModalVisible] = React.useState(false);
  const { title, image, longDescription, type, url } = data;
  // const { user } = useSelector((state) => state.auth);
  const [offsetModal, setOffsetModal] = React.useState(false);

  const history = useHistory();

  const selectBenefit = () => {
    if (user.type === "employer") {
      if (data.selected)
        Modal.confirm({
          title: "Deselect Benefit",
          content:
            "This benefit will now disappear for your employees. You can always re-select it when you want.",
          okText: "Confirm",
          onOk() {
            removeBenefitFromEmployer(data?._id);
          },
        });
      else {
        Modal.confirm({
          title: "Select Benefit",
          content:
            "You’ve selected an advocacy organization. It will now appear as an organization that your employees can choose to donate to. If they contribute, your environmental impact dashboard will be updated.",
          okText: "Confirm",
          onOk() {
            addBenefitToEmployer(data?._id);
          },
        });
      }
    }
  };

  return (
    <div>
      <EditBenefit
        setModalVisible={setModalVisible}
        isModalVisible={isModalVisible}
      />
      <h1 className={`${style.mainHeading} ${page ? "" : "mb-2"}`}>
        {page
          ? `Available Resources > ${title}`
          : `Available Climate Benefits > ${title}`}
      </h1>
      {type === "Volunteering Opportunities" ? (
        ""
      ) : page || type === "Advocacy" ? (
        <h3 style={{ marginBottom: "1em", fontSize: "1em" }}>
          This is an advocacy organization. Contributing to this benefit goes to
          the organization in general, not specifically to carbon removal.
        </h3>
      ) : page || type === "Renewable Energy Credits" ? (
        ""
      ) : (
        <h3 style={{ marginBottom: "1em", fontSize: "1em" }}>
          For every $1 contributed, {title} will remove{" "}
          {data.moneyToCarbonRatio} tons of carbon dioxide or equivalent from
          the atmosphere.
        </h3>
      )}
      <div className={style.CardDetail}>
        <img
          src={`${environment.serverurl}/img/${
            page ? page : imgRoute
          }/${image}`}
          style={{ width: "220px", height: "300px" }}
          alt=''
        />
        <div className={style.cardContent}>
          <div>
            <h3>{title}</h3>
            <p
              className={style.detailContent}
              dangerouslySetInnerHTML={{ __html: `${longDescription}` }}></p>
            {type === "Advocacy" && userType === "benefitprovider" ? (
              <div>
                <h5>Users Involved</h5>
                <ul>
                  {data.involved.map((item) => (
                    <li>{item.email} </li>
                  ))}
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            {userType === "benefitprovider" ? (
              <>
                <button
                  className='primaryBtn align-self-center mr-3'
                  onClick={() => setModalVisible(data)}>
                  Edit Benefit
                </button>
                <button
                  className='primaryBtn align-self-center'
                  onClick={() => {
                    Modal.confirm({
                      title: "Delete Benefit",
                      content: (
                        <div>
                          <p>
                            This benefit will be deleted permanently if you hit
                            Ok.
                          </p>
                        </div>
                      ),
                      async onOk() {
                        message.info("deleting benefit");
                        await deleteBenefit(data._id);
                        history.push(`/${userType}/benefits`);
                      },
                    });
                  }}>
                  Delete Benefit
                </button>
              </>
            ) : (
              <>
                {user.type === "employer" &&
                (data.type == "Carbon Removal" ||
                  data.type == "Renewable Energy Credits") ? (
                  <>
                    <button
                      type='button'
                      className='primaryBtn align-self-center mr-4'
                      onClick={() => setOffsetModal(true)}>
                      Purchase
                    </button>
                    <button
                      type='button'
                      className='primaryBtn align-self-center mr-4'
                      onClick={() => selectBenefit()}>
                      {user.type === "employer"
                        ? data.selected
                          ? "Selected"
                          : "Select"
                        : user.type === "employee"
                        ? data.type === "Volunteering Opportunities"
                          ? "Volunteer"
                          : "Allocate Funds"
                        : false}
                    </button>
                  </>
                ) : (
                  ""
                )}
                <a
                  href={url}
                  target='_blank'
                  rel='noreferrer'
                  className='primaryBtn align-self-center'>
                  {page ? "View Resource" : "Learn More"}
                </a>
              </>
            )}
            <OffsetPurchase
              visible={offsetModal}
              setVisible={setOffsetModal}
              data={data}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default connect(mapState, dispatchState)(CardDetail);
