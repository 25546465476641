import React from "react";
import style from "./index.module.css";

export const NftCard = (props) => {
  const data = props.data;
  return (
    <div className={style.nftCard}>
      <div
        className={style.cardHeader}
        style={data.id == "1" ? { color: "#ffffff" } : { color: "#000" }}
      >
        {data.title}
      </div>
      <img
        className={style.cardImg}
        style={data.id == "1" ? { width: "100%" } : { width: "" }}
        src={`/images/${data.image}`}
        alt=""
      />
      <div className={style.cardIcons}>
        {data.id == "1" ? (
          <img src="/images/eye-icon.svg" alt="" />
        ) : (
          <img src="/images/dark-eye.svg" alt="" />
        )}
      </div>
    </div>
  );
};
