import TYPES from "../constants/apikeys";

const initialState = {
  api_keys: [],
  api_logs: [],
};

const apiKeysReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_API_KEY: {
      let newState = Object.assign({}, state);
      newState.api_keys = action.payload;
      return newState;
    }

    case TYPES.API_KEY_LOGS: {
      let newState = Object.assign({}, state);
      newState.api_logs = action.payload;
      return newState;
    }
  }
  return state;
};

export default apiKeysReducer;
