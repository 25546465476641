import React from "react";
import { Modal, Button } from "antd";
import CardInfo from "../CardInfo";
import { useSelector, useDispatch } from "react-redux";

const CreditCardModal = (props) => {
  const cardModal = useSelector((state) => state.cardModal?.openCardModal);
  const dispatch = useDispatch();

  const handleOk = () => {
    dispatch({ type: "CLOSE_CARD_MODAL" });
  };

  const handleCancel = () => {
    dispatch({ type: "CLOSE_CARD_MODAL" });
  };

  return (
    <>
      <Modal
        bodyStyle={{
          height: "auto",
          paddingBottom: "80px",
        }}
        title="Please Add a Payment Method"
        visible={cardModal}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
      >
        <CardInfo onPlanSubmit={props.onPlanSubmit} />
      </Modal>
    </>
  );
};
export default CreditCardModal;
