import Types from '../constants/auth'
import BenefitTypes from '../constants/benefits'

import axios from 'axios'
import apiRequest from '../../api/apiRequest'
import { message, Modal } from 'antd'
import { fetchBenefits } from './benefits'
import { getTemplate } from './dashboard'
import _ from 'lodash'
import { getToken, onMessageListener } from '../../firebase'
import { fetchLeaderBoard, fetchEmployerSettings } from './benefits'

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
// actions
export const setUser = (data) => ({
  type: Types.SET_USER,
  payload: data,
})

export const setUserType = (view) => ({
  type: Types.SET_USER_TYPE,
  payload: view,
})

export const sendRegisterNotification = () => {
  apiRequest({
    method: 'GET',
    url: '/users/onboarding-success',
  }).catch((err) => {
    message.error(err.data.data)
  })
}

export const getCurrency = (currency) => async (dispatch) => {
  if (!currency) currency = 'USD'
  const api = `https://api.exchangeratesapi.io/v1/latest?access_key=491ff9c3879811eb25a82e95a940dca8&base=${currency}`
  axios(api).then((res) => {
    let data = res.data
    dispatch({
      type: Types.SET_CURRENCY,
      payload: {
        rates: data['rates'],
        currencies: Object.keys(data['rates']).sort(),
      },
    })
  })

  const api2 = `https://api.exchangeratesapi.io/v1/latest?access_key=491ff9c3879811eb25a82e95a940dca8&base=USD`
  axios(api2).then((res) => {
    let data = res.data
    dispatch({
      type: Types.SET_CURRENCY_USD,
      payload: {
        rates: data['rates'],
        currencies: Object.keys(data['rates']).sort(),
      },
    })
  })
}

export const meUser = () => async (dispatch) => {
  const token = await getToken()

  apiRequest({
    method: 'POST',
    url: '/users/me',
    data: {
      deviceToken: token ? token : 'not',
    },
  })
    .then(async (res) => {
      dispatch(setUser(res.data.data))
    })
    .catch((err) => {
      message.error(err.data.data)
    })
}

// actions
export const setCurrency = (currency) => async (dispatch) => {
  return await apiRequest({
    method: 'get',
    url: `/users/change-currency/${currency}`,
  })
    .then((res) => {
      dispatch(setUser(res.data.data))
      message.success('Currency set successfully')
    })
    .catch((err) => {
      message.error(err.data.data)
      return false
    })
}

export const editEmployee = (data) => async (dispatch) => {
  apiRequest({
    method: 'PUT',
    url: `/users/employee/${data._id}`,
    data,
  })
    .then(async (res) => {
      dispatch(setUser(res.data.data))
    })
    .catch((err) => {
      message.error(err.data.data)
    })
}

export const switchEmployer = () => async (dispatch, getState) => {
  const user = await getState().auth.user
  return await apiRequest({
    method: 'get',
    url: `/users/switchemployer/${
      user.type == 'employer' ? 'employee' : 'employer'
    }`,
  })
    .then(async (res) => {
      dispatch(setUser({ ...res.data.data.user, token: res.data.data.token }))
      localStorage.setItem('auth_token', res.data.data.token)
      setTimeout(async () => {
        await dispatch(fetchLeaderBoard())
        await dispatch(fetchEmployerSettings())

        await dispatch(fetchBenefits())
        await dispatch(fetchDashboardData())
        if (res.data.data.user.type == 'employer') {
          await dispatch(getTemplate())
          await dispatch(fetchBenefitFeedback())
          await dispatch(fetchEmployees())
        }
      }, 1000)
      return true
    })
    .catch((err) => {
      message.error(err.data.data)
      return false
    })
}

export const makeAdmin = (data) => async (dispatch, getState) => {
  const user = await getState().auth.user
  return await apiRequest({
    method: 'post',
    url: '/users/admin-access',
    data: { employeeId: data, admin: true },
  })
    .then(async (res) => {
      await dispatch(fetchEmployees())

      Modal.success({ title: 'Selected User is successfully made admin' })
      return true
    })
    .catch((err) => {
      message.error(err.data.data)
      return false
    })
}
// actions
export const signIn = (data) => async (dispatch) => {
  return await apiRequest({
    method: 'post',
    url: '/users/login',
    data: data,
  })
    .then((res) => {
      dispatch(setUser({ ...res.data.data.user, token: res.data.data.token }))

      return res.data.data.user.type
    })
    .catch((err) => {
      message.error('Invalid Email or Password')
      return false
    })
}

export const signUp = (data) => async (dispatch) => {
  return await apiRequest({
    method: 'post',
    url: '/users',
    data,
  })
    .then(async (res) => {
      dispatch(setUser({ ...res.data.data.user, token: res.data.data.token }))
      await dispatch(fetchBenefits())

      return true
    })
    .catch((err) => {
      message.error(err?.data?.data)
      return false
    })
}

export const updateUser = (data) => async (dispatch) => {
  return await apiRequest({
    method: 'put',
    url: '/users',
    data,
  })
    .then((res) => {
      Modal.success({ title: 'Account settings updated successfully.' })
      dispatch(setUser({ ...res.data.data }))
      return true
    })
    .catch((err) => {
      Modal.error({ title: 'Unable to update the account right now.' })
      return false
    })
}

export const setMonthlyLimit = (data) => async (dispatch, getState) => {
  const user = await getState().auth.user
  return await apiRequest({
    method: 'POST',
    url: '/users/onboarding/setmonthlylimit',
    data,
  })
    .then((res) => {
      dispatch(setUser({ ...user, details: res.data.data.details }))
      return true
    })
    .catch((err) => {
      return false
    })
}

export const fetchEmployees = () => async (dispatch) => {
  await apiRequest({
    method: 'get',
    url: '/users',
  })
    .then((res) => {
      dispatch({
        type: Types.SET_EMPLOYEES,
        payload: res.data.data.filter((item) => !item.employerAccount),
      })
      return true
    })
    .catch((err) => {
      return false
    })
}

export const fetchTransactions = () => async (dispatch) => {
  await apiRequest({
    method: 'get',
    url: '/transactions',
  })
    .then((res) => {
      dispatch({
        type: Types.SET_TRANSACTIONS,
        payload: res.data.data,
      })
      return true
    })
    .catch((err) => {
      return false
    })
}

export const addEmployee = (data) => async (dispatch, getState) => {
  let allEmployees = await getState().auth.employees
  return await apiRequest({
    method: 'post',
    url: '/users/addemployee',
    data,
  })
    .then((res) => {
      dispatch({
        type: Types.SET_EMPLOYEES,
        payload: [res.data.data, ...allEmployees],
      })
      return true
    })
    .catch((err) => {
      message.error(err.data.data)
      return false
    })
}

export const deleteEmployee = (employeeId) => async (dispatch, getState) => {
  let allEmployees = await getState().auth.employees

  return await apiRequest({
    method: 'post',
    url: `/users/deleteemployee`,
    data: { employeeids: employeeId },
  })
    .then((res) => {
      dispatch({
        type: Types.SET_EMPLOYEES,
        payload: allEmployees.filter(
          (employee) => !employeeId.includes(employee._id)
        ),
      })
      return true
    })
    .catch((err) => {
      message.error()
      return false
    })
}

export const inviteEmployee = (data) => async (dispatch, getState) => {
  let allEmployees = await getState().auth.employees

  return await apiRequest({
    method: 'post',
    url: '/users/inviteemployee',
    data,
  })
    .then((res) => {
      dispatch({
        type: Types.SET_EMPLOYEES,
        payload: allEmployees.map((item) => {
          if (data.employeeids.includes(item._id)) item.status = 'Pending'

          return item
        }),
      })
      Modal.success({ title: 'Invite Sent', content: 'Invite sent via email' })
      return true
    })
    .catch((err) => {
      return false
    })
}

export const addBenefitToEmployer = (data) => async (dispatch, getState) => {
  let benefits = await getState().benefits.benefits
  return await apiRequest({
    method: 'get',
    url: `/benefits/select/${data}`,
  })
    .then((res) => {
      dispatch({
        type: BenefitTypes.SET_BENEFITS,
        payload: benefits.map((item) => {
          if (item._id == data)
            if (item.selected) item['selected'] = false
            else item['selected'] = true
          return item
        }),
      })
      return true
    })
    .catch((err) => {
      return false
    })
}

export const selectAllBenefits = () => async (dispatch, getState) => {
  let benefits = await getState().benefits.benefits

  return await apiRequest({
    method: 'get',
    url: `/benefits/select-all`,
  })
    .then((res) => {
      dispatch({
        type: BenefitTypes.SET_BENEFITS,
        payload: benefits.map((item) => {
          item['selected'] = true
          return item
        }),
      })
      return true
    })
    .catch((err) => {
      return false
    })
}

export const removeBenefitFromEmployer =
  (data) => async (dispatch, getState) => {
    let benefits = await getState().benefits.benefits
    return await apiRequest({
      method: 'delete',
      url: `/benefits/select/${data}`,
    })
      .then((res) => {
        dispatch({
          type: BenefitTypes.SET_BENEFITS,
          payload: benefits.map((item) => {
            if (item._id == data)
              if (item.selected) item['selected'] = false
              else item['selected'] = true
            return item
          }),
        })
        return true
      })
      .catch((err) => {
        return false
      })
  }

export const fetchDashboardData = () => async (dispatch, getState) => {
  return await apiRequest({
    method: 'get',
    url: `/dashboard`,
  })
    .then((res) => {
      dispatch({
        type: Types.SET_DASHBOARD_DATA,
        payload: res.data.data,
      })
      return true
    })
    .catch((err) => {
      return false
    })
}

export const savePaymentDetail = (data) => async (dispatch, getState) => {
  return await apiRequest({
    method: 'post',
    url: `/users/addpaymentmethod`,
    data,
  })
    .then((res) => {
      // dispatch({
      //   type: Types.SET_DASHBOARD_DATA,
      //   payload: res.data.data,
      // });
      return true
    })
    .catch((err) => {
      return false
    })
}

export const finchConnect = (data) => async (dispatch, getState) => {
  const employees = await getState().auth.employees

  return await apiRequest({
    method: 'get',
    url: `/finch/addemployees?code=${data}`,
    data,
  })
    .then((res) => {
      message.success('Employees added')
      let data = [...res.data.data, ...employees]
      data = _.uniqBy(data, 'id')
      dispatch({
        type: Types.SET_EMPLOYEES,
        payload: data,
      })

      return true
    })
    .catch((err) => {
      message.success('unable to connect payroll provider')

      return false
    })
}
export const setCardApiKey = () => {
  return apiRequest({
    method: 'get',
    url: `/users/setupCard`,
  })
    .then((res) => {
      return res.data.data
    })
    .catch((err) => {
      return false
    })
}

export const setCard = (data) => async (dispatch, getState) => {
  return await apiRequest({
    method: 'post',
    url: `/card`,
    data,
  })
    .then((res) => {
      message.success('Card Updated Successfully')

      return true
    })
    .catch((err) => {
      message.success('unable to connect payroll provider')

      return false
    })
}

export const getNotifications = (data) => async (dispatch, getState) => {
  return await apiRequest({
    method: 'get',
    url: `/notifications`,
    data,
  })
    .then((res) => {
      dispatch({
        type: Types.SET_NOTIFICATIONS,
        payload: res.data.data,
      })
      return true
    })
    .catch((err) => {
      message.success('unable to fetch notifications right now.')

      return false
    })
}

export const resetPassword = async (data) => {
  return await apiRequest({
    method: 'post',
    url: `/users/forgotpassword`,
    data,
  })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      message.error(err.data.data)

      return false
    })
}

export const resetPasswordCode = async (data) => {
  return await apiRequest({
    method: 'post',
    url: `/users/resetpassword`,
    data,
  })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      message.error(err.data.data)

      return false
    })
}

export const addGlobalText = (data) => async (dispatch, getState) => {
  const user = await getState().auth.user

  return await apiRequest({
    method: 'post',
    url: `/users/addabout`,
    data,
  })
    .then((res) => {
      dispatch(setUser({ ...user, about: data.about }))

      return res.data
    })
    .catch((err) => {
      message.error(err.data.data)

      return false
    })
}

export const changePricingPlan = (data) => async (dispatch, getState) => {
  return await apiRequest({
    method: 'post',
    url: `/users/select-plan`,
    data,
  })
    .then((res) => {
      dispatch(meUser())
      return res.data
    })
    .catch((err) => {
      // message.error(err.data.data);

      return false
    })
}

export const fetchBenefitFeedback = (data) => async (dispatch, getState) => {
  return await apiRequest({
    method: 'get',
    url: `/benefitfeedback`,
  })
    .then((res) => {
      dispatch({ type: Types.SET_BENEFIT_FEEDBACK, payload: res.data.data })
      return true
    })
    .catch((err) => {
      // message.error(err.data.data);
      message.error('Error in fetching benefit feedback: ')
      return false
    })
}

export const changeLeaderboard = (data) => async (dispatch, getState) => {
  var body = {
    points: data.points,
    leaderboard: data.leaderboard,
    tutorial: data.tutorial,
    brandName: data.brandName,
  }

  if (data.image) body['image'] = await toBase64(data.image)

  return await apiRequest({
    method: 'post',
    url: `/points-and-leaderboard/toggle-settings`,
    data: body,
  })
    .then((res) => {
      dispatch(meUser())
      return true
    })
    .catch((err) => {
      message.error(err.data.data)
      return false
    })
}
