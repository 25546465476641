import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import CreditCard from "./CreditCard";
import CardInfo from "./CardInfo";
import SubscriptionPlan from "./SubscriptionPlan";
import { useSelector, connect } from "react-redux";
import moment from "moment";
import { createInvoice, createAllInvoices } from "../redux/actions/benefits";
import style from "../style/ClientBenefits.module.css";
import { ConvertCurrency } from "../utils";

const { TabPane } = Tabs;

const mapState = (state) => ({
  user: state.auth.user,
});

const Payments = ({ user }) => {
  const [cardEdit, setCardEdit] = useState(false);
  const [cardData, setCardData] = useState({ cvc: "", expiry: "", card: "" });
  let rates = useSelector((state) => state.auth.currencyRates.rates);
  const payment = useSelector((state) => state.auth.user.paymentMethods);
  const transactions = useSelector((state) => state.auth.transactions);

  useEffect(() => {
    if (user.type == "employer" && user.details) {
      const { cvc, expiry, card } = user.details;
      setCardData({ cvc, expiry, card });
    }
    document
      .querySelector("#google_translate_element")
      .classList.add("visible_translate");
    return () => {
      document
        .querySelector("#google_translate_element")
        .classList.remove("visible_translate");
    };
  }, []);

  return (
    <div>
      <Tabs>
        <TabPane tab='Payment Methods' key='1'>
          <div className='row'>
            <div className='col-6'>
              {!cardEdit && payment && payment.length > 0 ? (
                <CreditCard onEdit={() => setCardEdit(true)} />
              ) : (
                <CardInfo
                  onCardChange={(data) => {
                    setCardData(data);
                  }}
                  cardEdit={cardEdit}
                  onCancel={() => setCardEdit(false)}
                  cardData={cardData}
                />
              )}{" "}
            </div>
          </div>
        </TabPane>
        <TabPane tab='Subscriptions' key='2'>
          <div className='d-flex align-items-center'>
            <span>
              Your allocated funds for employees and monthly payment to Climate
              Benefits will appear here.
            </span>
            {transactions.length > 0 ? (
              <button
                className='ml-auto primaryBtn mr-1 mb-2'
                onClick={() => createAllInvoices()}>
                Download All
              </button>
            ) : (
              ""
            )}
          </div>
          <table className={style.subscription_table}>
            <colgroup>
              <col width='10%' />
              <col width='25%' />
              <col width='25%' />
              <col width='25%' />
            </colgroup>
            <thead>
              <tr>
                <th>#</th>
                <th>Transaction Date</th>
                <th>Amount</th>
                <th>Card Number</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                  <td>
                    {ConvertCurrency(user.details.currency, rates, item.amount)}
                  </td>
                  <td>{item.cardNumber}</td>
                  <td>
                    <button
                      className='primaryBtn'
                      onClick={() => createInvoice(item._id)}>
                      Invoice
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TabPane>
        <TabPane tab='Pricing Plan' key='3'>
          <SubscriptionPlan />
        </TabPane>
      </Tabs>
    </div>
  );
};
export default connect(mapState)(Payments);
