import React from "react";
import { Tabs, Alert } from "antd";

const { TabPane } = Tabs;

const apiDocs = [
  {
    id: "1",
    menuName: "Purchase Offset Post",
    title: "Purchase Offset Post",
    method: "POST",
    endPoint: "/purchaseOffset_post",
    description:
      "Purchase Offset API is a POST method. You can look at the payload and response to get an idea of what will you send or what will you get. ",
    apiURL: "https://admindev.climatebenefits.com/api/v2/purchaseOffset_post",
    payload: {
      contribution: "30",
      benefitId: "62923fbcd71dd61d5ce847c0",
    },
    response: "Response : Success",
  },
  {
    id: "2",
    menuName: "Get All Provider Details",
    title: "Get All Provider Details",
    method: "GET",
    endPoint: "/all/providers/benifits_get",
    description:
      "Get All Provider Details is a GET method. You can look at the payload and response to get an idea of what will you send or what will you get. ",
    apiURL:
      "https://admindev.climatebenefits.com/api/v2/all/providers/benifits_get",
    response: "Response : Success",
  },
  {
    id: "3",
    menuName: "Get Sustainability Alerts",
    title: "Get Sustainability Alerts",
    method: "POST",
    endPoint: "/alerts/tags_post",
    description:
      "Get Sustainability Alerts API is a POST method. You can look at the payload and response to get an idea of what will you send or what will you get. ",
    apiURL: "https://admindev.climatebenefits.com/api/v2/alerts/tags_post",
    payload: {
      tags: "['Climate']",
    },
    response: "Response : Success",
  },
  {
    id: "4",
    menuName: "Calculate Carbon",
    title: "Calculate Carbon",
    method: "POST",
    endPoint: "/calculateCarbon",
    description:
      "Calculate Carbon API is a POST method. You can look at the payload and response to get an idea of what will you send or what will you get. ",
    apiURL: "https://admindev.climatebenefits.com/api/v2/calculateCarbon",
    payload: {
      country: "United States",
      industry: "Energy - Coal, Oil & Gas",
      offices: 1,
      employees: 1,
      employeesRemote: 1,
      flights: 0,
      electricity: 0,
      energyProgram: false,
      energyEfficiency: false,
    },
    response: {
      status: 200,
      CarbonFootprint: "368.32",
    },
  },
];

const Docs = () => {
  return (
    <>
      <Tabs defaultActiveKey="1" tabPosition="left">
        {apiDocs.map((doc) => (
          <TabPane tab={doc.menuName} key={doc.id}>
            <p className="font-weight-bold " style={{ color: "#999999" }}>
              {doc.title}
            </p>
            <div className="d-flex align-items-center my-3">
              <h3 className="text-success">{doc.method}</h3>
              <h6 className="mx-5  ">{doc.endPoint}</h6>
            </div>
            <Alert
              message={doc.apiURL}
              type="success"
              showIcon
              className="mt-3 mb-3"
              style={{ width: "500px" }}
            />
            <h6 className="my-3">{doc.description}</h6>
            <Tabs defaultActiveKey="1" type="card">
              <TabPane tab="Payload" key="1">
                <figure>
                  <pre>
                    <code>{JSON.stringify(doc.payload, null, 5)}</code>
                  </pre>
                  {/* <figcaption>jokes.json</figcaption> */}
                </figure>
              </TabPane>
              <TabPane tab="Response" key="2">
                <figure>
                  <pre>
                    <code>{JSON.stringify(doc.response, null, 5)}</code>
                  </pre>
                  {/* <figcaption>jokes.json</figcaption> */}
                </figure>
              </TabPane>
            </Tabs>
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default Docs;
