import React, { useState } from "react";
import style from "../style/ClientBenefits.module.css";
import { createBenefit } from "../redux/actions/benefits";
import { connect } from "react-redux";
import { Form, Input, Button, Checkbox, Select } from "antd";
import { useHistory } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const { Option } = Select;

const mapDispatch = (dispatch) => ({
  addBenefit: (data) => dispatch(createBenefit(data)),
});

const mapState = (state) => ({
  benefit: state.benefits.active_benefit,
});

function Submit({ Data, type, addBenefit }) {
  const [fieldTitle, setFieldTitle] = useState();
  const [fileChange, setFileChange] = useState("");
  const [editorState, setEditorState] = useState();

  const history = useHistory();

  const handleChange = (value) => {
    setFieldTitle(value);
  };

  const onEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  const onFinish = async (values) => {
    if (type == "provider") {
      let html = stateToHTML(editorState.getCurrentContent());

      await addBenefit({ ...values, image: fileChange, longDescription: html });
      history.push(`/benefitprovider/benefits`);
    }
  };

  const onFinishFailed = (errorInfo) => {};
  return (
    <div className={`${style.container} pb-5`}>
      <div className={style.ClientBenefits}>
        <h1 className="mb-4">
          Submit your product or service to the Climate Benefits platform
        </h1>
      </div>
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="row" style={{ width: "100%", maxWidth: "700px" }}>
          {Data.map((item) => (
            <div className={`col-md-${item.col}`}>
              {item.type == "input" ? (
                <Form.Item
                  label=""
                  name={item.value}
                  rules={[
                    {
                      required: true,
                      message: `Please input your ${item.title.toLowerCase()}!`,
                    },
                  ]}
                >
                  <Input placeholder={item.title} className={style.input} />
                </Form.Item>
              ) : item.type == "textarea" ? (
                <Form.Item
                  label=""
                  rules={[
                    {
                      required: true,
                      message: `Please input your ${item.title.toLowerCase()}!`,
                    },
                  ]}
                >
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName={style.editor}
                    onEditorStateChange={onEditorChange}
                  />
                </Form.Item>
              ) : item.type == "select" ? (
                <Form.Item
                  label=""
                  name={item.value}
                  rules={[
                    {
                      required: true,
                      message: `Please input your ${item.title.toLowerCase()}!`,
                    },
                  ]}
                >
                  <Select
                    placeholder={`Select a ${item.title}`}
                    style={{ width: "100%", border: "none" }}
                    // placeholder={item.title}
                    className={`${style.input} borderSelect`}
                    onChange={handleChange}
                  >
                    {item.option.map((opt) => (
                      <Option value={opt}>{opt}</Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : item.type == "file" ? (
                <Form.Item
                  label=""
                  name={item.value}
                  rules={[
                    {
                      required: true,
                      message: `Please select an image`,
                    },
                  ]}
                >
                  <input
                    type="file"
                    className={style.input}
                    onChange={(e) =>
                      e.target.files ? setFileChange(e.target.files[0]) : ""
                    }
                  />
                </Form.Item>
              ) : (
                ""
              )}
            </div>
          ))}
          {fieldTitle == "Carbon Removal" ||
          fieldTitle == "Renewable Energy Credits" ? (
            <div className="col-md-12">
              <Form.Item
                label=""
                name={"moneyToCarbonRatio"}
                rules={[
                  {
                    required: true,
                    message:
                      fieldTitle == "Renewable Energy Credits"
                        ? `Please input Tons of RCE does each contributed dollar reduce?!`
                        : `Please input Tons of carbon does each contributed dollar reduce?!`,
                  },
                ]}
              >
                <Input
                  placeholder={
                    fieldTitle == "Renewable Energy Credits"
                      ? "What is your price per ton of REC?"
                      : "What is your price per ton of carbon removed?"
                  }
                  className={style.input}
                />
              </Form.Item>
            </div>
          ) : (
            ""
          )}
          <div className="col-md-12">
            <button type="submit" className="primaryBtn">
              Submit
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default connect(mapState, mapDispatch)(Submit);
