const initialState = {
  openIntro: false,
};

const introReducer = (state = initialState, { type }) => {
  if (type == "OPEN_INTRO") {
    return { openIntro: true };
  }
  if (type == "CLOSE_INTRO") {
    return { openIntro: false };
  }
  return state;
};

export default introReducer;
