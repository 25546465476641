import React, { useState, useEffect } from "react";
import CreditCardInput from "react-credit-card-input";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { setCard, setCardApiKey } from "../redux/actions/auth";
import { useDispatch } from "react-redux";
import { message, Modal, Spin } from "antd";
import { setUser } from "../redux/actions/auth";
import apiRequest from "../api/apiRequest";

export default function CardInfo(props) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [cardKey, setCardKey] = useState("");
  const [loading, setLoading] = useState(false);
  const cardModal = useSelector((state) => state.cardModal?.openCardModal);
  const { onPlanSubmit } = props;
  const handleCancel = () => {
    dispatch({ type: "CLOSE_CARD_MODAL" });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    setCardApiKey().then((res) => {
      stripe
        .confirmCardSetup(res, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: user.email,
            },
          },
        })

        .then(function (result) {
          setLoading(false);

          if (result.error) {
            Modal.error({ title: "Error", content: result.error.message });
            // Display error.message in your UI.
          } else {
            apiRequest({
              method: "POST",
              url: "/users/me",
            }).then(async (res) => {
              const user = res.data.data;
              dispatch(setUser(user));
              Modal.success({ content: "Payment method added successfully" });

              if (props.cardEdit) props.onCancel();
              if (cardModal) handleCancel();
            });

            // The setup has succeeded. Display a success message.
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    });
  };
  const { fullWidth } = props;
  return (
    <form
      onSubmit={handleSubmit}
      style={{
        width: fullWidth ? "100%" : "auto",
        display: fullWidth ? "flex" : "block",
      }}
    >
      <CardElement
        className={`${fullWidth ? "fullWidthCardElement" : ""} cardElement`}
        options={{
          style: {
            base: {
              iconColor: "#175CFF",
              fontSize: "22px",
              color: "black",
              backgroundColor: "transparent",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              iconColor: "#175CFF",
              color: "#9e2146",
            },
          },
        }}
      />
      {/* <CreditCardInput
        className={`${fullWidth ? "fullWidthCardElement" : ""} cardElement`}
        cardElementProps={{
          style: {
            base: {
              iconColor: "#175CFF",
              fontSize: "22px",
              color: "black",
              backgroundColor: "transparent",
              "::placeholder": {
                color: "#AAB7C4",
              },
            },
            invalid: {
              iconColor: "#175CFF",
              color: "#9E2146",
            },
          },
        }}
      /> */}

      <div className={`${fullWidth ? "" : "col-12"}  text-right`}>
        {props.cardEdit ? (
          <button
            className="mr-2 primaryBtn"
            type="submit"
            onClick={() => {
              props.onCancel();
            }}
          >
            Cancel
          </button>
        ) : (
          ""
        )}

        {loading ? (
          <div className="ml-3 my-3 mr-0">
            <Spin color="white" />
          </div>
        ) : (
          <button className="primaryBtn" type="submit" disabled={!stripe}>
            {onPlanSubmit ? "Pay Now" : "Save"}
          </button>
        )}
      </div>
    </form>
  );
}
