import React from "react";
import style from "../style/signupForm.module.css";
import { resetPassword } from "../redux/actions/auth";
import { Form, Input, Button, Checkbox, message, Space } from "antd";

export default function ForgetForm() {
  const onFinish = async (values) => {
    const result = await resetPassword(values);
    if (result)
      message.success(
        "An email with password reset link has been sent to your email. please check"
      );
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <div className={style.signInForm}>
        <h2>Forgot Password ?</h2>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="row">
            <div className="col-md-12">
              <Form.Item
                label=""
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email!",
                  },
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>
            <div className="col-md-12 text-right">
              <Space>
                <button className="primaryBtn" type="submit">
                  Request Reset
                </button>
              </Space>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}
