import React, { useState } from "react";
import style from "../style/ClientBenefits.module.css";
import { Select } from "antd";
import MainCard from "../components/MainCard";
import { connect } from "react-redux";
import { setActiveBenefit } from "../redux/actions/benefits";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ConvertCurrency, numberWithCommas } from "../utils";

const mapState = (state) => ({
  dashboard: state.auth.dashboard,
  user: state.auth.user,
  benefits: state.benefits.benefits.filter((item) => item.selected),
});

const mapDispatch = (Dispatch) => ({
  setActiveBenefit: (data) => Dispatch(setActiveBenefit(data)),
});

const { Option } = Select;

function CompanyOverview({
  user,
  SignUpFormData,
  dashboard,
  setActiveBenefit,
  benefits,
}) {
  const history = useHistory();
  const [filter, setFilter] = useState("");
  let rates = useSelector((state) => state.auth.currencyRates.rates);

  function handleChange(value) {
    setFilter(value);
  }

  const infoCardDataEmployer = [
    {
      title: "Participating Employees",
      count: dashboard.employeesParticipating
        ? dashboard.employeesParticipating
        : 0,
    },
    {
      title: "Carbon Dioxide Removed",
      count: `${
        dashboard.totalOrganizationCarbonImpact
          ? parseFloat(dashboard.totalOrganizationCarbonImpact).toFixed(2)
          : "0"
      } Tons`,
    },
    {
      title: "Volunteering Opportunities",
      count: dashboard.volunteeringOpportunities
        ? dashboard.volunteeringOpportunities
        : 0,
    },

    {
      icon: "",
      title: "Number Of Benefits",
      count: `${benefits.length}`,
    },
    {
      icon: "",
      title: "Total Donated",
      count: `${ConvertCurrency(
        user.details.currency,
        rates,
        dashboard.carbonRemovalDonated + dashboard.advocacyMoneyDonated
      )}`,
    },
    {
      icon: "",
      title: "Donated to Carbon Removal",
      count: `${ConvertCurrency(
        user.details.currency,
        rates,
        dashboard.carbonRemovalDonated ? dashboard.carbonRemovalDonated : 0
      )}`,
    },
    {
      icon: "",
      title: "Donated to Climate Advocacy",
      count: `${ConvertCurrency(
        user.details.currency,
        rates,
        dashboard.advocacyMoneyDonated ? dashboard.advocacyMoneyDonated : 0
      )}`,
    },
    {
      icon: "co2_icon.svg",
      title: "Carbon Footprint",
      count: `${
        dashboard.carbonFootprint
          ? numberWithCommas(dashboard.carbonFootprint)
          : "0"
      } Tons`,
    },
  ];

  const { pageTitle } = SignUpFormData;

  let tempBenefits =
    pageTitle === "employee"
      ? dashboard.benefits
        ? dashboard.benefits
        : []
      : benefits;
  if (filter && filter !== "All")
    tempBenefits = tempBenefits.filter((item) => item.type === filter);

  return (
    <div>
      <div className={style.ClientBenefits}>
        <div className='d-flex flex-wrap mb-5'>
          {infoCardDataEmployer.map((card) => (
            <div className={`${style.infoCard} mb-2`}>
              {card.icon ? <img src={`/images/${card.icon}`} alt='' /> : ""}
              <div>
                <p>{card.title}</p>
                <h6>{card.count}</h6>
              </div>
            </div>
          ))}
        </div>

        <div className='d-flex align-items-center justify-content-between mb-4'>
          <h1>My Climate Benefits</h1>
          <Select
            defaultValue='Sort By'
            style={{ width: 120 }}
            onChange={handleChange}>
            <Option value='All'>All</Option>
            <Option value='Carbon Removal'>Carbon Removal</Option>
            <Option value='Advocacy'>Advocacy</Option>
          </Select>
        </div>

        <div className='row '>
          {tempBenefits.map((card) => (
            <div className='col-lg-6' style={{ marginBottom: "30px" }}>
              <MainCard
                Data={card}
                imgRoute={"benefits"}
                userType={user.type}
                setDetailVisible={() => {
                  setActiveBenefit(card);
                  history.push(`/${user.type}/benefits/detail`);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, mapDispatch)(CompanyOverview);
