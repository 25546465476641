import React, { useEffect } from "react";
import style from "../style/ClientBenefits.module.css";
import { Collapse, Empty } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAPILogs } from "../redux/actions/apikeys";

const { Panel } = Collapse;
const APILogs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAPILogs());
  }, []);

  const apiLogs = useSelector((state) => state?.apikey?.api_logs);

  return (
    <div style={{ marginBottom: "100px" }}>
      {apiLogs?.length > 1 ? (
        <Collapse bordered={true} className='site-collapse-custom-collapse'>
          {apiLogs
            ?.map((log) => {
              return (
                <Panel
                  header={`${log.url} - ${log.method} - ${
                    log.status
                  } - ${new Date(log.createdAt)}`}
                  key={log._id}
                  className='site-collapse-custom-panel'>
                  <p>
                    <b>Payload</b>: {log.payload ?? "Null"}
                  </p>
                </Panel>
              );
            })
            .reverse()}
        </Collapse>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};

export default APILogs;
